import React from 'react'
import './stylesheets/Summary.scss'
import axios from 'axios'

class Summary extends React.Component{

    state = {

        users : 0,
        submissions : 0,
        kineto : 0,
        efs : 0,
        confirmati : 0,
        refuzati : 0,
        curs : 0,
        neverificati : 0,
        licenta: 0,
        master : 0,
        doctorat :0,
        other :0

    }
    componentDidMount = async () => {

        let summaryInfo = await axios.get('/api/admin/getSummary/' + localStorage.getItem('id'));

        this.setState({
            users : summaryInfo.data.users,
            submissions : summaryInfo.data.submissions,
            kineto : summaryInfo.data.submissionsKineto,
            efs : summaryInfo.data.submissionsEFS,
            confirmati: summaryInfo.data.confirmati,
            refuzati: summaryInfo.data.refuzati,
            curs: summaryInfo.data.curs,
            neverificati: summaryInfo.data.neverificati,
            licenta : summaryInfo.data.licenta,
            master : summaryInfo.data.master,
            doctorat :summaryInfo.data.doctorat,
            other: summaryInfo.data.other
        })
        // console.log(summaryInfo);
    }
    render(){

        return(
            <div className = "Summary-container">
                <div className = "Summary-content">
                    <h3 id = "title" style = {{marginBottom: "20px"}}> Informatii </h3>
                    <p> <strong>{this.state.users}</strong> Conturi </p>
                    <p> <strong>{this.state.submissions}</strong> Aplicatii </p>
                    <p> <strong>{this.state.confirmati}</strong> Confirmati </p>
                    <p> <strong>{this.state.refuzati}</strong> Cu documente refuzate </p>
                    <p> <strong>{this.state.curs}</strong> In curs de verificare </p>
                    <p> <strong>{this.state.neverificati}</strong> Fara documente incarcate </p>
                    <br/>
                    <p> <strong>{this.state.efs} </strong> EFS </p>
                    <p> <strong>{this.state.kineto}</strong> Kineto </p>
                    <br/>
                    <p> <strong>{this.state.licenta} </strong> Licenta </p>
                    <p> <strong>{this.state.master}</strong> Master </p>
                    <p> <strong>{this.state.doctorat}</strong> Doctorat </p>
                    <p> <strong>{this.state.other}</strong> Altii </p>

                </div>
            </div>

        )
    }
}

export default Summary;