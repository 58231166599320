import React from 'react';
import {
  BrowserRouter as Router,
  Route } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css'

//pages
import DashboardStudent from './Pages/Student/Dashboard'
import Login from './Pages/Login'
import SignupLogin from './Pages/SignupLogin'
import ConfirmEmail from './Pages/ConfirmEmail'
import ResetPassword from './Pages/ResetPassword'

import DashboardAdmin from './Pages/Admin/Dashboard'

function App() {
  return (
    <div className="App">
        <header className="App-header">
          <Router>
            <ToastContainer autoClose = {1500}/>
            <Route exact path = "/" component = {Login} />
            <Route exact path = "/login" component = {Login} />
            <Route exact path = "/signup" component = {SignupLogin} />
            <Route exact path = "/confirmEmail/:id" component = {ConfirmEmail} />
            <Route exact path = "/resetPassword/:id" component = {ResetPassword} />

            <Route exact path = "/dashboardAdmin" component = {DashboardAdmin} />
            <Route exact path = "/dashboardStudent" component = {DashboardStudent} />

          </Router>
        </header>
    </div>
  );
}

export default App;
