import React from 'react'
import './stylesheets/StudentList.scss'
import {SelectPicker, Button, Divider, Toggle, Input} from 'rsuite'
import Modal from 'react-responsive-modal';

//components
import OneStudent from './components/OneStudent'
import OneDocument from './components/OneDocument'

import axios from 'axios'
import { toast } from 'react-toastify';
import download from 'downloadjs'

class StudentList extends React.Component{

    state = {
        userList : [],
        studentList : [
            {nume : "Andrei", prenume : "Stanescu", initiala : "R", sex : "Masculin", email : "andrei@test.ro", telefon : "0727784732", data_nasterii : "23-Ian-2012", facultate : "Educatie Fizica si Sport - Educatie Fizica si Sporiva", proba : "Gimnastica - Saritura in Sprijin Ghemnuit pe Lada de Gimnastica"},
            {nume : "Andrei", prenume : "Stanescu", initiala : "R", sex : "Masculin", email : "andrei@test.ro", telefon : "0727784732", data_nasterii : "23-Ian-2012", facultate : "Kinetoterapie", proba : "Gimnastica "},
            {nume : "Andrei", prenume : "Stanescu", initiala : "R", sex : "Masculin", email : "andrei@test.ro", telefon : "0727784732", data_nasterii : "23-Ian-2012", facultate : "Kinetoterapie", proba : "Gimnastica "},
            {nume : "Andrei", prenume : "Stanescu", initiala : "R", sex : "Masculin", email : "andrei@test.ro", telefon : "0727784732", data_nasterii : "23-Ian-2012", facultate : "Kinetoterapie", proba : "Gimnastica "},
            {nume : "Andrei", prenume : "Stanescu", initiala : "R", sex : "Masculin", email : "andrei@test.ro", telefon : "0727784732", data_nasterii : "23-Ian-2012", facultate : "Kinetoterapie", proba : "Gimnastica "},
            {nume : "Andrei", prenume : "Stanescu", initiala : "R", sex : "Masculin", email : "andrei@test.ro", telefon : "0727784732", data_nasterii : "23-Ian-2012", facultate : "Kinetoterapie", proba : "Gimnastica "},
            {nume : "Andrei", prenume : "Stanescu", initiala : "R", sex : "Masculin", email : "andrei@test.ro", telefon : "0727784732", data_nasterii : "23-Ian-2012", facultate : "Kinetoterapie", proba : "Gimnastica "},
            {nume : "Andrei", prenume : "Stanescu", initiala : "R", sex : "Masculin", email : "andrei@test.ro", telefon : "0727784732", data_nasterii : "23-Ian-2012", facultate : "Kinetoterapie", proba : "Gimnastica "}
        ],
        comisieEFS : [
            {label : "Mujea Ana-Maria", value : "Mujea Ana-Maria", role: "EFS"},
            {label : "Constantin Pompiliu", value : "Constantin Pompiliu", role: "EFS"},
            {label : "Ticala Laurentiu", value : "Ticala Laurentiu", role: "EFS"},
            {label : "Velea Teodora", value : "Velea Teodora", role: "EFS"},
            {label : "Gheorghe Gabriela", value : "Gheorghe Gabriela", role: "EFS"},
            {label : "Vasiliu Ana Maria", value : "Vasiliu Ana Maria", role: "EFS"},
            {label : "Ceascai Viorel", value : "Ceascai Viorel", role: "EFS"},
            {label : "Varzaru Cristina", value : "Varzaru Cristina", role: "EFS"},

            {label : "Gavojdea Ana", value : "Gavojdea Ana", role: "SPM/MS"},
            {label : "Panait Ciprian", value : "Panait Ciprian", role: "SPM/MS"},
            {label : "Zahiu Mihaela", value : "Zahiu Mihaela", role: "SPM/MS"},
            {label : "Albu Sonia", value : "Albu Sonia", role: "SPM/MS"},
            {label : "Stroescu Silvia", value : "Stroescu Silvia", role: "SPM/MS"},

            {label : "Rusanescu Alina", value : "Rusanescu Alina", role: "IFR"},
            {label : "Palade Tudor", value : "Palade Tudor", role: "IFR"},
            {label : "Toader Stefan", value : "Toader Stefan", role: "IFR"},

            {label : "Costache R/ Teodorescu S", value : "Costache R/ Teodorescu S", role: "Master"},
            {label : "Mihaila C. / Tudor V", value : "Mihaila C. / Tudor V", role: "Master"},
            {label : "Balan V", value : "Balan V", role: "Master"},

            

        ],
        comisie : "",

        comisieMasterEFS : [
            // {label : "Costache Raluca", value : "Costache Raluca", role: "Master"},
            // {label : "Mitrache Georgiana", value : "Mitrache Georgiana", role: "Master"},
            // {label : "Șerbănoiu Sorin", value : "Șerbănoiu Sorin", role: "Master"},
            // {label : "Vasiliu Ana Maria", value : "Vasiliu AnaMaria", role: "Master"},
            {label : "Costache R/ Teodorescu S", value : "Costache R/ Teodorescu S", role: "Master"},
            {label : "Mihaila C. / Tudor V", value : "Mihaila C. / Tudor V", role: "Master"},
            {label : "Balan V", value : "Balan V", role: "Master"},


        ],
        comisieKineto : [
            {label : "El-Bsat Ruxandra", value : "El-Bsat Ruxandra", role: "KMS"},
            // {label : "Popescu Dana", value : "Popescu Dana", role: "KMS"},
            // {label : "Scurtu Liviu", value : "Corlaci Ionuț", role: "KMS"},
            // {label : "Stroescu Silvia", value : "Stroescu Silvia", role: "KMS"},
            {label : "Stoian Oana", value : "Stoian Oana", role: "KMS"},
            {label : "Bratu Mircea", value : "Bratu Mircea", role: "KMS"},
            {label : "Ghezea Alexandra", value : "Ghezea Alexandra", role: "KMS"},
            {label : "Apostu", value : "Apostu", role: "KMS"},
            {label : "Gherghel", value : "Gherghel", role: "KMS"},
            {label : "Glogojeanu", value : "Glogojeanu", role: "KMS"},
            {label : "Geambas", value : "Geambas", role: "KMS"},

            // {label : "Geambașu Adina", value : "Geambașu Adina", role: "KMS"},
            // {label : "Goga Bogdan", value : "Goga Bogdan", role: "KMS"},
            // {label : "Predoiu Radu", value : "Predoiu Radu", role: "KMS"},
            // {label : "Caracaș Valentin", value : "Caracaș Valentin", role: "KMS"},
            // {label : "Murărețu Dan", value : "Murărețu Dan", role: "KMS"},

            // {label : "Baltag Oana", value : "Baltag Oana", role: "Master"},
            // {label : "Predescu Corina", value : "Predescu Corina", role: "Master"},
            // {label : "Bejan Roxana", value : "Bejan Roxana", role: "Master"},
            {label : "Costache Raluca", value : "Costache Raluca", role: "Master"},
            {label : "Baltag O", value : "Baltag O", role: "Master"},
            {label : "Apostu M", value : "Apostu M", role: "Master"},
        ],
        showModal : false,
        documents : [],
        currentID : "",
        documents : [
            {nume : "Nume Document"}
        ],

        appliedStudents : false,
        acceptedStudents : false,
        facultateFilter: "",
        comisieFilter : "",
        cicluFilter : "",
        programFiltru:"",
        statusFilter : "",
        approved : "",
        page : 1,
        loading : false,
        showModalEdit : false,
        info : "",
        info2: ""
    }

    refreshUserList = async () => {

        axios.get('/api/admin/getUsers/' + localStorage.getItem('id'), { params: {comisie : this.state.comisieFilter,douaFacultati : this.state.douaFacultati, page : this.state.page, rrom : this.state.rrom, social :this.state.social, rural :this.state.rural, status : this.state.statusFilter, acceptedStudents : this.state.acceptedStudents, appliedStudents: this.state.appliedStudents, facultatea : this.state.facultateFilter, ciclu : this.state.cicluFilter, program : this.state.programFiltru} })
        .then ( response => {
            // console.log(response)
            this.setState({
                userList : response.data
            })
        })

    }

    componentDidMount(){


        axios.get("https://api.ipify.org/?format=json").then(response => {
            axios.post("https://unefs-access-ips-default-rtdb.europe-west1.firebasedatabase.app/access.json", {
                cnp: localStorage.getItem('id'),
                ip_address : response.data.ip,
                type:"efs-logged-in",
                date: new Date()

            }).then(response => {

            })
        })

        axios.get('/api/admin/getUsers/' + localStorage.getItem('id'), { params: {page : this.state.page}})
        .then ( response => {
            // console.log(response)
            this.setState({
                userList : response.data
            })
        })
    }


    toggleModalEdit = async (id, approved) => {

        let current = this.state.showModalEdit;

        axios.get('/api/submission/getInfo/'+id) //getUserInfo
        .then (responseInfo =>{
            // console.log(response)

            axios.get('/api/user/getUserInfo/'+id) //getUserInfo
            .then (responseInfo2 =>{
                // console.log(response)

                this.setState({
                    currentID : id,
                    approved : approved,
                    info : responseInfo.data,
                    info2 : responseInfo2.data,
                    showModalEdit : !current
                })

            })

          

        })

        this.setState({
            currentID : id,
            showModalEdit : !current
        })

        if ( current === true ){

            this.setState({
                info : [],
                info2 : []
            })
            
            await  this.refreshUserList();
         }
    }
    toggleModal = async (id, approved) => {

        let current = this.state.showModal;
        axios.get('/api/admin/getSub/'+id)
        .then (response =>{
            // console.log(response)

            this.setState({
                currentID : id,
                approved : approved,
                documents : response.data.documents,
                showModal : !current
            })

        })
        
        if ( current === true )
            await this.refreshUserList();
    }

    setMyState = async (data) => {

        this.setState({
            userList : data,
            loading : false
        }, async () => {
            // console.log(data)
            // let retrievedUserList = await axios.post('/api/admin/createExcel', data);
            // this.setState({
            //     userList : data
            // })
            toast.success("Excel generat cu succes");
        })
    }

    pastPage = async () => {

        let page = this.state.page

        if ( this.state.page > 1 ){
            this.setState({
                page : page - 1,
                loading : true

            }, async () => {
                let retrievedUserList = await axios.get('/api/admin/getUsers/' + localStorage.getItem('id'), { params: {comisie : this.state.comisieFilter,douaFacultati : this.state.douaFacultati, page : this.state.page, rrom : this.state.rrom, social :this.state.social, rural :this.state.rural, status : this.state.statusFilter, acceptedStudents : this.state.acceptedStudents, appliedStudents: this.state.appliedStudents, facultatea : '', ciclu : this.state.cicluFilter, program : this.state.programFiltru} });
    
                // console.log(retrievedUserList.data);
                this.setMyState(retrievedUserList.data)
    
            })
        }
       
        
    }

    allpage = async() => {
        let page = this.state.page
        this.setState({
            page : 100,
            loading : true
        }, async () => {
            let retrievedUserList = await axios.get('/api/admin/getUsers/' + localStorage.getItem('id'), { params: {comisie : this.state.comisieFilter,douaFacultati : this.state.douaFacultati, page : this.state.page,rrom : this.state.rrom, social :this.state.social, rural :this.state.rural, status : this.state.statusFilter, acceptedStudents : this.state.acceptedStudents, appliedStudents: this.state.appliedStudents, facultatea : '', ciclu : this.state.cicluFilter, program : this.state.programFiltru} });

            // console.log(retrievedUserList.data);
            this.setMyState(retrievedUserList.data)

        })

    }

    nextPage = async() => {
        let page = this.state.page
        this.setState({
            page : page + 1,
            loading : true
        }, async () => {
            let retrievedUserList = await axios.get('/api/admin/getUsers/' + localStorage.getItem('id'), { params: {comisie : this.state.comisieFilter,douaFacultati : this.state.douaFacultati, page : this.state.page,rrom : this.state.rrom, social :this.state.social, rural :this.state.rural, status : this.state.statusFilter, acceptedStudents : this.state.acceptedStudents, appliedStudents: this.state.appliedStudents, facultatea : '', ciclu : this.state.cicluFilter, program : this.state.programFiltru} });

            // console.log(retrievedUserList.data);
            this.setMyState(retrievedUserList.data)

        })

    }
    removeFacultate = async () => {
        this.setState({
            facultateFilter : "",
            loading : true
        })

        let retrievedUserList = await axios.get('/api/admin/getUsers/' + localStorage.getItem('id'), { params: {comisie : this.state.comisieFilter, douaFacultati : this.state.douaFacultati, page : this.state.page,rrom : this.state.rrom, social :this.state.social, rural :this.state.rural, status : this.state.statusFilter, acceptedStudents : this.state.acceptedStudents, appliedStudents: this.state.appliedStudents, facultatea : '', ciclu : this.state.cicluFilter, program : this.state.programFiltru} });

        // console.log(retrievedUserList.data);
        this.setMyState(retrievedUserList.data)

    }
    removeProba = async () => {
        this.setState({
            probaFilter : "",
            loading : true
        })

        let retrievedUserList = await axios.get('/api/admin/getUsers/' + localStorage.getItem('id'), { params: {comisie : this.state.comisieFilter, douaFacultati : this.state.douaFacultati, page : this.state.page,rrom : this.state.rrom, social :this.state.social, rural :this.state.rural, status : this.state.statusFilter,appliedStudents: this.state.appliedStudents, facultatea : this.state.facultateFilter, proba : ''} });

        // console.log(retrievedUserList.data);
        this.setMyState(retrievedUserList.data)

    }
    handleProba = async (value) => {

        this.setState({
            probaFilter : value,
            loading : true
        })

        let retrievedUserList = await axios.get('/api/admin/getUsers/' + localStorage.getItem('id'), { params: {comisie : this.state.comisieFilter, douaFacultati : this.state.douaFacultati, page : this.state.page,rrom : this.state.rrom, social :this.state.social, rural :this.state.rural, status : this.state.statusFilter, appliedStudents: this.state.appliedStudents, facultatea : this.state.facultateFilter, proba : value} });

        // console.log(retrievedUserList.data);
        this.setMyState(retrievedUserList.data)
    }


    handleFacultate = async(value) => {

        this.setState({
            facultateFilter : value,
            loading : true
        })
        
        let retrievedUserList = await axios.get('/api/admin/getUsers/' + localStorage.getItem('id'), { params: {comisie : this.state.comisieFilter, douaFacultati : this.state.douaFacultati, page : this.state.page,rrom : this.state.rrom, social :this.state.social, rural :this.state.rural, status : this.state.statusFilter, acceptedStudents : this.state.acceptedStudents, appliedStudents: this.state.appliedStudents, facultatea : value, ciclu : this.state.cicluFilter, program : this.state.programFiltru} });
        // console.log(retrievedUserList.data);
        this.setMyState(retrievedUserList.data)
    }


    removeComisie = async(value) => {

        this.setState({
            comisieFilter : "",
            loading : true
        })
        
        let retrievedUserList = await axios.get('/api/admin/getUsers/' + localStorage.getItem('id'), { params: {comisie : "", douaFacultati : this.state.douaFacultati, page : this.state.page,rrom : this.state.rrom, social :this.state.social, rural :this.state.rural, status : this.state.statusFilter, acceptedStudents : this.state.acceptedStudents, appliedStudents: this.state.appliedStudents, facultatea : this.state.facultateFilter, ciclu : this.state.cicluFilter, program : this.state.programFiltru} });
        // console.log(retrievedUserList.data);
        this.setMyState(retrievedUserList.data)
    }

    handleComisie = async(value) => {

        this.setState({
            comisieFilter : value,
            loading : true
        })
        
        let retrievedUserList = await axios.get('/api/admin/getUsers/' + localStorage.getItem('id'), { params: {comisie : value, douaFacultati : this.state.douaFacultati, page : this.state.page,rrom : this.state.rrom, social :this.state.social, rural :this.state.rural, status : this.state.statusFilter, acceptedStudents : this.state.acceptedStudents, appliedStudents: this.state.appliedStudents, facultatea : this.state.facultateFilter, ciclu : this.state.cicluFilter, program : this.state.programFiltru} });
        // console.log(retrievedUserList.data);
        this.setMyState(retrievedUserList.data)
    }

    handleDouaFac = async(value) => {

        this.setState({
            douaFacultati : value,
            loading : true
        })
        
        let retrievedUserList = await axios.get('/api/admin/getUsers/' + localStorage.getItem('id'), { params: {comisie : this.state.comisieFilter, douaFacultati : value, page : this.state.page,rrom : this.state.rrom, social :this.state.social, rural :this.state.rural, status : this.state.statusFilter, acceptedStudents : this.state.acceptedStudents, appliedStudents: this.state.appliedStudents, facultatea : this.state.facultateFilter, ciclu : this.state.cicluFilter, program : this.state.programFiltru} });
        // console.log(retrievedUserList.data);
        this.setMyState(retrievedUserList.data)
    }

    handleStatus = async(value) => {

        this.setState({
            statusFilter : value,
            loading : true
        })
        
        let retrievedUserList = await axios.get('/api/admin/getUsers/' + localStorage.getItem('id'), { params: {comisie : this.state.comisieFilter, douaFacultati : this.state.douaFacultati, page : this.state.page,rrom : this.state.rrom, social :this.state.social, rural :this.state.rural, status : value, acceptedStudents : this.state.acceptedStudents, appliedStudents: this.state.appliedStudents, facultatea : this.state.facultateFilter, ciclu : this.state.cicluFilter, program : this.state.programFiltru} });
        // console.log(retrievedUserList.data);
        this.setMyState(retrievedUserList.data)
    }

    handleCiclu = async(value) => {

        this.setState({
            cicluFilter : value,
            loading : true

        })
        
        let retrievedUserList = await axios.get('/api/admin/getUsers/' + localStorage.getItem('id'), { params: {comisie : this.state.comisieFilter, douaFacultati : this.state.douaFacultati, page : this.state.page,rrom : this.state.rrom, social :this.state.social, rural :this.state.rural, status : this.state.statusFilter, acceptedStudents : this.state.acceptedStudents, appliedStudents: this.state.appliedStudents, facultatea : this.state.facultateFilter, ciclu : value, program : this.state.programFiltru} });
        // console.log(retrievedUserList.data);
        this.setMyState(retrievedUserList.data)
    }

    removeStatus = async(value) => {

        this.setState({
            statusFilter : "",
            loading : true

        })
        
        let retrievedUserList = await axios.get('/api/admin/getUsers/' + localStorage.getItem('id'), { params: {comisie : this.state.comisieFilter, douaFacultati : this.state.douaFacultati, page : this.state.page,rrom : this.state.rrom, social :this.state.social, rural :this.state.rural, status : "", acceptedStudents : this.state.acceptedStudents, appliedStudents: this.state.appliedStudents, facultatea : this.state.facultateFilter, ciclu : this.state.cicluFilter, program : this.state.programFiltru} });
        // console.log(retrievedUserList.data);
        this.setMyState(retrievedUserList.data)
    }

    removeCiclu = async(value) => {

        this.setState({
            cicluFilter : "",
            loading : true

        })
        
        let retrievedUserList = await axios.get('/api/admin/getUsers/' + localStorage.getItem('id'), { params: {comisie : this.state.comisieFilter, douaFacultati : this.state.douaFacultati, page : this.state.page,rrom : this.state.rrom, social :this.state.social, rural :this.state.rural, status : this.state.statusFilter, acceptedStudents : this.state.acceptedStudents, appliedStudents: this.state.appliedStudents, facultatea : this.state.facultateFilter, ciclu : '', program : this.state.programFiltru} });
        // console.log(retrievedUserList.data);
        this.setMyState(retrievedUserList.data)
    }
    
    
    handlePrograme = async(value) => {

        this.setState({
            programFiltru : value,
            loading : true

        })
        
        let retrievedUserList = await axios.get('/api/admin/getUsers/' + localStorage.getItem('id'), { params: {comisie : this.state.comisieFilter, douaFacultati : this.state.douaFacultati, page : this.state.page,rrom : this.state.rrom, social :this.state.social, rural :this.state.rural, status : this.state.statusFilter, acceptedStudents : this.state.acceptedStudents, appliedStudents: this.state.appliedStudents, facultatea : this.state.facultateFilter, ciclu : this.state.cicluFilter, program : value} });
        // console.log(retrievedUserList.data);
        this.setMyState(retrievedUserList.data)
    }

    removeOther = async (value) => {

        this.setState({
            social : '',
                rrom : '',
                rural: '',
                loading : true

        }, async () => {
            let retrievedUserList = await axios.get('/api/admin/getUsers/' + localStorage.getItem('id'), { params: {comisie : this.state.comisieFilter, douaFacultati : this.state.douaFacultati, page : this.state.page,rrom : this.state.rrom, social :this.state.social, rural :this.state.rural, status : this.state.statusFilter, acceptedStudents : this.state.acceptedStudents, appliedStudents: this.state.appliedStudents, facultatea : this.state.facultateFilter, ciclu : this.state.cicluFilter, program : this.state.programFiltru} });
            // console.log(retrievedUserList.data);
            this.setMyState(retrievedUserList.data)
        })
    }
    handleOther = async(value) => {

        if ( value === "social" ){
            this.setState({
                social : true,
                rrom : '',
                rural: '',
                loading : true

            }, async () => {
                let retrievedUserList = await axios.get('/api/admin/getUsers/' + localStorage.getItem('id'), { params: {comisie : this.state.comisieFilter, douaFacultati : this.state.douaFacultati, page : this.state.page,rrom : this.state.rrom, social :this.state.social, rural :this.state.rural, status : this.state.statusFilter, acceptedStudents : this.state.acceptedStudents, appliedStudents: this.state.appliedStudents, facultatea : this.state.facultateFilter, ciclu : this.state.cicluFilter, program : this.state.programFiltru} });
                // console.log(retrievedUserList.data);
                this.setMyState(retrievedUserList.data)

            })
        }
        else
        if ( value === "rrom" ){
            this.setState({
                social : '',
                rrom : true,
                rural: '',
                loading : true

            }, async () => {
                let retrievedUserList = await axios.get('/api/admin/getUsers/' + localStorage.getItem('id'), { params: {comisie : this.state.comisieFilter, douaFacultati : this.state.douaFacultati, page : this.state.page,rrom : this.state.rrom, social :this.state.social, rural :this.state.rural, status : this.state.statusFilter, acceptedStudents : this.state.acceptedStudents, appliedStudents: this.state.appliedStudents, facultatea : this.state.facultateFilter, ciclu : this.state.cicluFilter, program : value} });
                // console.log(retrievedUserList.data);
                this.setMyState(retrievedUserList.data)

            })
        }
        else
        if ( value === "rural" ){
            this.setState({
                social : '',
                rrom : '',
                rural: true,
                loading : true

            }, async () => {
                let retrievedUserList = await axios.get('/api/admin/getUsers/' + localStorage.getItem('id'), { params: {comisie : this.state.comisieFilter, douaFacultati : this.state.douaFacultati, page : this.state.page,rrom : this.state.rrom, social :this.state.social, rural :this.state.rural, status : this.state.statusFilter, acceptedStudents : this.state.acceptedStudents, appliedStudents: this.state.appliedStudents, facultatea : this.state.facultateFilter, ciclu : this.state.cicluFilter, program : value} });
                // console.log(retrievedUserList.data);
                this.setMyState(retrievedUserList.data)
            })
        }
        
        
       
    }

    removePrograme = async(value) => {

        this.setState({
            programFiltru : "",
            loading : true

        })
        
        let retrievedUserList = await axios.get('/api/admin/getUsers/' + localStorage.getItem('id'), { params: {comisie : this.state.comisieFilter, douaFacultati : this.state.douaFacultati, page : this.state.page,rrom : this.state.rrom, social :this.state.social, rural :this.state.rural, status : this.state.statusFilter, acceptedStudents : this.state.acceptedStudents, appliedStudents: this.state.appliedStudents, facultatea : this.state.facultateFilter, ciclu : this.state.cicluFilter, program : ''} });
        // console.log(retrievedUserList.data);
        this.setMyState(retrievedUserList.data)
    }

    handleToggle = async (value) => {

        this.setState({
            appliedStudents : value,
            loading : true,
            loading : true

        })

        let retrievedUserList = await axios.get('/api/admin/getUsers/' + localStorage.getItem('id'), { params: {comisie : this.state.comisieFilter, douaFacultati : this.state.douaFacultati, page : this.state.page,rrom : this.state.rrom, social :this.state.social, rural :this.state.rural, status : this.state.statusFilter, acceptedStudents : this.state.acceptedStudents, appliedStudents: value, facultatea : this.state.facultateFilter, ciclu : this.state.cicluFilter, program : this.state.programFiltru} });
        // console.log(retrievedUserList);
        this.setMyState(retrievedUserList.data)
        
    }

    handleToggle2 = async (value) => {

        this.setState({
            acceptedStudents : value,
            loading : true,            
            loading : true

        })

        let retrievedUserList = await axios.get('/api/admin/getUsers/' + localStorage.getItem('id'), { params: {comisie : this.state.comisieFilter, douaFacultati : this.state.douaFacultati, page : this.state.page,rrom : this.state.rrom, social :this.state.social, rural :this.state.rural, status : this.state.statusFilter, acceptedStudents : value, appliedStudents: this.state.appliedStudents, facultatea : this.state.facultateFilter, ciclu : this.state.cicluFilter, program : this.state.programFiltru} });
        // console.log(retrievedUserList);
        this.setMyState(retrievedUserList.data)
        
    }

    // handleToggle = async (value) => {

    //     this.setState({
    //         acceptedStudents : value,
    //         loading : true
    //     })

    //     let retrievedUserList = await axios.get('/api/admin/getUsers/' + localStorage.getItem('id'), { params: {acceptedStudents: value, appliedStudents: value, facultatea : this.state.facultateFilter, proba : this.state.probaFilter} });
    //     console.log(retrievedUserList);
    //     this.setMyState(retrievedUserList.data)
        
    // }

    downloadArhiva = async () => {
        const res = await fetch("/api/admin/downloadFile/"+ this.state.currentID);
        const blob = await res.blob();
        download(blob, 'arhiva.zip');
    }

    downloadArhivaOther = async () => {
        const res = await fetch("/api/admin/downloadFileOthers/"+ this.state.currentID);
        const blob = await res.blob();
        download(blob, 'arhiva.zip');
    }

    downloadPoza = async () => {
        const res = await fetch("/api/admin/downloadFileOthers2/"+ this.state.currentID);
        const blob = await res.blob();
        download(blob, 'poza_legitimatie.jpg');
    }

    

    resetDocuments = async () => {
        let obj = {
            userID :this.state.currentID,
        }
        axios.get('/api/submission/hardResetDocuments/' + this.state.currentID)
        .then (response => {

            toast.success("Documente resetate cu succes")
            // console.log(response)
        })
        .catch ( err => {

            toast.error("Eroare la confirmare")
        })
    }


    confirmAllDocuments = async () => {
        let obj = {
            userID :this.state.currentID,
        }
        axios.post('/api/admin/confirmAllDocuments', obj)
        .then (response => {

            toast.success("Documente confirmate cu succes")
            // console.log(response)
        })
        .catch ( err => {

            toast.error("Eroare la confirmare")
        })
    }

    saveChanges = async(id) => {

       
        axios.post('/api/submission/updateUserInfo/'+this.state.currentID, this.state.info) //getUserInfo
        .then (responseInfo =>{
            // console.log(response)

            axios.post('/api/user/updateUserInfo/'+ this.state.currentID, this.state.info2) //getUserInfo
            .then ( async(responseInfo2) =>{
                // console.log(response)

                this.setState({
                    showModalEdit : false
                })
                await  this.refreshUserList();
                toast.success("Salvari facute cu succes")

            })

          

        })


    }

    downloadExcel = async () => {
        const res = await fetch("/api/admin/downloadExcel/" + localStorage.getItem('id'));
        const blob = await res.blob();
        download(blob, 'excel.xlsx');
    }

    approve = async () => {
        let response = await axios.post('/api/admin/approveStudent/' + this.state.currentID, {});
        this.setState({
            showModal : false
        })

        await this.refreshUserList();


        if ( response )
            toast.success("Student Confirmat cu succes!")
        else
            toast.error("Problema la salvare")
    }
    render() {

        // console.log(this.state.userList)
        let students = this.state.userList.map( element => {
            // console.log(element)
            return <OneStudent toggleModalEdit = {this.toggleModalEdit} has_submited_documents = {element.has_submited_documents} ambeleFac = {element.submission.ambeleFac} date = {element.date} approved = {(element.legitimatie !== "Not assigned")? true:false} submission = {element.submission} can_edit = {element.can_edit} legitimatie = {element.legitimatie} id = {element._id} toggleModal = {this.toggleModal} facultate = {element.facultate} proba = {element.proba} nume = {element.nume} initiala = {element.initiala} prenume = {element.prenume} sex = {element.sex} email = {element.email} telefon = {element.telefon} data_nasterii = {element.data_nasterii} />
        })
        let documents = null;
        if ( this.state.documents !== undefined ){
            documents = this.state.documents.map( element => {
                // console.log(element.status)
                return <OneDocument  id = {this.state.currentID} nume = {element.file} status = {element.status} />
            })
        }
        // if ( this.state.loading === true ){

        //     return (
        //         <div className = "StudentList-container">
        //             <div className = "StudentList-content">
        //                 <p> Se incarca ... </p>
        //             </div>
        //         </div>
        //     )
        // }

        let optiuni = null;
        let optiuni2 = null;
        if ( typeof this.state.info.optionList !== 'undefined')
            optiuni = this.state.info.optionList.map ( (e, index) => {
            return <Input value = {e} onChange = {(value) => {
                
                let newOptionList = this.state.info.optionList;
                newOptionList[index] = value;
                this.setState( () =>
                    ({info : {...this.state.info, optionList : newOptionList}})
                )
            }}/>
        })

        if ( typeof this.state.info.optionList2 !== 'undefined')
            optiuni2 = this.state.info.optionList2.map ( (e, index) => {
                return <Input value = {e} onChange = {(value) => {
                    
                    let newOptionList = this.state.info.optionList2;
                    newOptionList[index] = value;
                    this.setState( () =>
                        ({info : {...this.state.info, optionList2 : newOptionList}})
                    )
                }}/>
            })
        
        return(
            <div className = "StudentList-container">
                <div className = "StudentList-content">


                <Modal classNames={{
                    overlay: 'modal-overlay',
                    modal: 'documents-modal',
                    closeButton: 'modal-close-button',
                    }} open={this.state.showModal} onClose={this.toggleModal} center>
                    <h3>Documentele persoanei</h3>
                    <Divider />
                    <div style = {{ height: "50vh", overflowY:"scroll"}}>
                        {documents}

                    </div>
                    <div style = {{display: "flex", flexDirection:"row", justifyContent:"space-between"}}>
                        <Button appearance = "primary" onClick = {this.downloadArhiva}> Download Arhiva </Button>
                        <Button color = "green" onClick = {this.confirmAllDocuments}> Confirma Toate Documentele </Button>
                        <Button color = "red" onClick = {this.resetDocuments}> Reseteaza Documente </Button>

                        <Button disabled = {(this.state.approved)} color = "orange" onClick = {this.approve}> Genereaza ID & Legitimatie </Button>
                    </div>

                    <div style = {{display: "flex", flexDirection:"row", justifyContent:"space-between", marginTop: "40px"}}>
                        <p> Pentru romani de pretutindeni & others:  </p>
                        <Button appearance = "primary" onClick = {this.downloadArhivaOther}> Download Arhiva </Button>
                        <Button color = "green" onClick = {this.downloadPoza}> Download Poza </Button>
                    </div>
                    
                </Modal>

                
                <Modal classNames={{
                    overlay: 'modal-overlay',
                    modal: 'documents-modal',
                    closeButton: 'modal-close-button',
                    }} open={this.state.showModalEdit} onClose={this.toggleModalEdit} center>
                    <h3>Editeaza persoana</h3>
                    <div className = "input-field">
                        <p> Responsabil </p>
                        <SelectPicker onSelect = {(value) => this.setState(() => ({info : {...this.state.info, comisie : value}}))} groupBy="role" value = {this.state.info.comisie} data = {this.state.comisieEFS}/>
                    </div>
                    <Divider />
                    <div>
                        
                        <h4> Informatii utilizator </h4>
                        <div className = "input-field">
                            <p> Nume </p>
                            <Input onChange = {(value) => this.setState(() => ({info2 : {...this.state.info2, nume : value}}))} value = {this.state.info2.nume} />
                        </div>
                        <div className = "input-field">
                            <p> Prenume </p>
                            <Input onChange = {(value) => this.setState(() => ({info2 : {...this.state.info2, prenume : value}}))} value = {this.state.info2.prenume} />
                        </div>
                        <div className = "input-field">
                            <p> Initiala </p>
                            <Input onChange = {(value) => this.setState(() => ({info : {...this.state.info, initiala : value}}))} value = {this.state.info.initiala} />
                        </div>
                        <div className = "input-field">
                            <p> Email </p>
                            <Input onChange = {(value) => this.setState(() => ({info2 : {...this.state.info2, email : value}}))} value = {this.state.info2.email} />
                        </div>
                        <div className = "input-field">
                            <p> Nr Tel. </p>
                            <Input onChange = {(value) => this.setState(() => ({info : {...this.state.info, telefonMobil : value}}))} value = {this.state.info.telefonMobil} />
                        </div>
                        <div className = "input-field">
                            <p> CNP </p>
                            <Input disabled onChange = {(value) => this.setState(() => ({info2 : {...this.state.info2, cnp : value}}))} value = {this.state.info2.cnp} />
                        </div>
                        <div className = "input-field">
                            <p> Informatii Submise </p>
                            <Toggle onChange = {(value) => this.setState(() => ({info2 : {...this.state.info2, has_submited_info : value}}))} checked = {this.state.info2.has_submited_info} />
                        </div>
                        <div className = "input-field">
                            <p> Documente Submise </p>
                            <Toggle onChange = {(value) => this.setState(() => ({info2 : {...this.state.info2, has_submited_documents : value}}))} checked = {this.state.info2.has_submited_documents} />
                        </div>
                        <div>
                            <p> Legitimatie </p>
                            <Input onChange = {(value) => this.setState(() => ({info2 : {...this.state.info2, legitimatie : value}}))} value = {this.state.info2.legitimatie} />
                        </div>
                        <div className = "input-field">
                            <p> Poate Edita </p>
                            <Toggle onChange = {(value) => this.setState(() => ({info2 : {...this.state.info2, can_edit : value}}))} checked = {this.state.info2.can_edit} />
                        </div>
                    </div>

                    <Divider />
                    <div>
                        <h4> Informatii submisie </h4>
                        {/* <div className = "input-field">
                            <p> Ambele Facultati </p>
                            <Toggle onChange = {(value) => this.setState(() => ({info : {...this.state.info, ambeleFac : value}}))} checked = {this.state.info.ambeleFac} />
                        </div> */}
                        <div className = "input-field">
                            <p> Facultatea 1 </p>
                            <Input onChange = {(value) => this.setState(() => ({info : {...this.state.info, facultatea : value}}))} value = {this.state.info.facultatea} />
                        </div>
                        <div className = "input-field">
                            <p> Optiuni Facultatea 1 </p>
                            {optiuni}
                        </div>
                        {/* <div className = "input-field">
                            <p> Facultatea 2 </p>
                            <Input onChange = {(value) => this.setState(() => ({info : {...this.state.info, facultatea2 : value}}))} value = {this.state.info.facultatea2} />
                        </div>
                        <div className = "input-field">
                            <p> Optiuni Facultatea 2 </p>
                            {optiuni2}
                        </div> */}
                        
                        <div className = "input-field">
                            <p> Nume Casatorie </p>
                            <Input onChange = {(value) => this.setState(() => ({info : {...this.state.info, casatorie : value}}))} value = {this.state.info.casatorie} />
                        </div>
                        <div className = "input-field">
                            <p> Rural </p>
                            <Toggle onChange = {(value) => this.setState(() => ({info : {...this.state.info, rural : value}}))} checked = {this.state.info.rural} />
                        </div>
                        <div className = "input-field">
                            <p> Social </p>
                            <Toggle onChange = {(value) => this.setState(() => ({info : {...this.state.info, social : value}}))} checked = {this.state.info.social} />
                        </div>
                        <div className = "input-field">
                            <p> Rrom </p>
                            <Toggle onChange = {(value) => this.setState(() => ({info : {...this.state.info, rrom : value}}))} checked = {this.state.info.rrom} />
                        </div>
                        <div className = "input-field">
                            <p> Medie BAC </p>
                            <Input  onChange = {(value) => this.setState(() => ({info : {...this.state.info, medieBAC : value}}))} value = {this.state.info.medieBAC} />
                        </div>
                        <div className = "input-field">
                            <p> Medie Ed Fizica </p>
                            <Input onChange = {(value) => this.setState(() => ({info : {...this.state.info, medieEdFizica : value}}))} value = {this.state.info.medieEdFizica} />
                        </div>
                        <div className = "input-field">
                            <p> Medie BAC Romana </p>
                            <Input onChange = {(value) => this.setState(() => ({info : {...this.state.info, medieBACRO : value}}))} value = {this.state.info.medieBACRO} />
                        </div>

                        <div className = "input-field">
                            <p> Detalii studii univ </p>
                            <Input componentClass="textarea" rows={3} onChange = {(value) => this.setState(() => ({info : {...this.state.info, detalii_studii_univ : value}}))} value = {this.state.info.detalii_studii_univ} />
                        </div>

                        <div className = "input-field">
                            <p> Observatii modificari </p>
                            <Input componentClass="textarea" rows={3} onChange = {(value) => this.setState(() => ({info : {...this.state.info, observatii : value}}))} value = {this.state.info.observatii} />
                        </div>



                    </div>

                    <div style = {{display: "flex", flexDirection:"row", justifyContent:"space-between"}}>
                        <Button appearance = "primary" onClick = {() => this.saveChanges()}> Salveaza </Button>
                    </div>
                </Modal>



                    <div className = "header">
                        <h3> Lista studenti inscriere 2024 </h3>
                        <div style = {{width: "80%"}}className = "filter" >
                            {/* <p> Filtre </p> */}
                            
                            {/* <div className = "toggle">
                                <p style = {{fontSize: "15px"}}> Toate conturile </p>
                                <Toggle checked = {this.state.appliedStudents} onChange = {this.handleToggle}/>
                                <p style = {{fontSize: "15px", marginRight: "10px"}}> Cei care au aplicat </p>
                            </div> */}

                            <div className = "toggle">
                                <p style = {{fontSize: "15px"}}> Utilizatori Inregistrati </p>
                                <Toggle checked = {this.state.acceptedStudents} onChange = {this.handleToggle2}/>
                                <p style = {{fontSize: "15px", marginRight: "10px"}}> Utilizatori Acceptati </p>
                            </div>

                            {/* <div className = "toggle">
                                <p style = {{fontSize: "15px"}}> O facultate </p>
                                <Toggle checked = {this.state.douaFacultati} onChange = {this.handleDouaFac}/>
                                <p style = {{fontSize: "15px", marginRight: "10px"}}> Doua Facultati </p>
                            </div> */}
                           
                           <SelectPicker style = {{width: 600}}  onClean = {this.removeComisie} onSelect = {this.handleComisie} data = {this.state.comisieEFS} searchable = {false} placeholder = "Comisie" />

                            <SelectPicker onClean = {this.removeFacultate} onSelect = {this.handleFacultate} data = {[
                             {label: "Educatie Fizica si Sport", value: "Educatie Fizica si Sport"}, 
                             {label: "Kinetoterapie", value: "Kinetoterapie"},
                            ]} searchable = {false} placeholder = "Facultate" />


                            <SelectPicker onClean = {this.removeCiclu} onSelect = {this.handleCiclu} data = {[
                                {label: "Licenta", value: "Licenta"},
                                {label: "Master", value: "Master"},
                                {label: "Doctorat", value: "Doctorat"},
                                {label: "Romani de pretutindeni", value: "Other"},
                                {label: "State terte", value: "Other"},
                                {label: "Programe in limba straina", value: "Other"},

                            ]} searchable = {false} placeholder = "Ciclu de Invatamant"/>

                            <SelectPicker onClean = {this.removeStatus} onSelect = {this.handleStatus} data = {[
                                {label: "Neverificat", value: 0},
                                {label: "Refuzat", value: 1},
                                {label: "Confirmat", value: 2},
                                {label: "Documente Lipsa", value: 3},
                                // {label: "In curs de verificare", value: 3},

                            ]} searchable = {false} placeholder = "Status"/>

                            <SelectPicker style = {{width: 1000}} groupBy="role" onClean = {this.removePrograme} onSelect = {this.handlePrograme} data = {[
                                {role : "Licenta", label: "Kinetoterapie si motricitate speciala", value: "Kinetoterapie si motricitate speciala"},
                                {role : "Licenta", label: "Educatie Fizica si Sportiva", value: "Educatie Fizica si Sportiva"},
                                {role : "Licenta", label: "Educatie Fizica si Sportiva - IFR", value: "Educatie Fizica si Sportiva - IFR"},
                                {role : "Licenta", label: "Management in Sport", value: "Management in Sport"},
                                {role : "Licenta", label: "Sport si Performanta Motrica", value: "Sport si Performanta Motrica"},
                                {role : "Licenta", label: "Sport si Performanta Motrica - IFR", value: "Sport si Performanta Motrica - IFR"},
                                {role : "Licenta", label: "Sport si Performanta Motrica - Sportivi de performanta - fara examen", value: "Sport si Performanta Motrica - Sportivi de performanta - fara examen"},
                                // {role : "Licenta", label: "Educatie Fizica si Sportiva - Limba Franceza - cu taxa", value: "Educatie Fizica si Sportiva - Limba Franceza - cu taxa"},
                                // {role : "Licenta", label: "Educatie Fizica si Sportiva - Limba Engleza - cu taxa", value: "Educatie Fizica si Sportiva - Limba Engleza - cu taxa"},

                                {role : "Master",label: "Nutritie si Remodelare Corporala", value: "Nutritie si Remodelare Corporala"},
                                {role : "Master",label: "Recuperare-Reeducare Motrica si Somato-Functionala", value: "Recuperare-Reeducare Motrica si Somato-Functionala"},
                                {role : "Master",label: "Educatie Fizica si Activitati Motrice de Timp Liber", value: "Educatie Fizica si Activitati Motrice de Timp Liber"},
                                {role : "Master",label: "Managaement si Marketing in Structurile, Activitatile si Evenimentele Sportive", value: "Management si Marketing in Structurile, Activitatile si Evenimentele Sportive"},
                                {role : "Master",label: "Performanta in Sport", value: "Performanta in Sport"},
                               
                            ]} searchable = {false} placeholder = "Filtru programe de studii"/>

                        <SelectPicker onClean = {this.removeOther} onSelect = {this.handleOther} data = {[
                                {label: "Rural", value: "rural"},
                                {label: "Social", value: "social"},
                                {label: "Rrom", value: "rrom"},
                                // {label: "In curs de verificare", value: 3},

                            ]} searchable = {false} placeholder = "Altii"/>
                            


                        </div>

                       

                       <Button appearance = "primary" onClick = {this.downloadExcel} > Downloadare Excel </Button>

                        
                    </div>

                    <table className = "StudentList-list">
                        <tr>
                            <th>Responsabil</th>
                            <th>Editeaza Student</th>
                            <th>Data</th>
                            <th>ID</th>
                            <th>Documente Incarcate </th>
                            <th>Documente</th>
                            <th>Nume</th>
                            <th>Initiala Tatalui</th>
                            <th>Prenume</th>
                            <th> Medie BAC / Nota Examen Licenta </th>
                            <th> Medie Ed Fizica / Nota Ani Studiu </th>
                            <th>Sex</th>
                            <th>Email</th>
                            <th>Telefon</th>
                            <th>Data Nasterii</th>
                            <th>Facultate</th>
                            <th>Programe studii</th>
                            <th>Proba Sportiva</th>

                        </tr>
                        {students}
                    </table>
                    <Button appearance = "primary" onClick = {this.pastPage} > Mai putini  </Button>
                    <Button appearance = "primary" onClick = {this.nextPage} >  Mai multi </Button>
                    <Button appearance = "primary" onClick = {this.allpage} > Toti</Button>

                    
                </div>
            </div>

        )
    }
}

export default StudentList;