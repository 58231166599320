import React from 'react'
import './DocumentStatusLine.scss'
import {Icon, Tooltip, Whisper, Button} from 'rsuite'
import Modal from 'react-responsive-modal';


class DocumentStatusLine extends React.Component{

    state = {
        showModal : false,
    }
    toggleModal = () =>{
        let current = this.state.showModal;

        this.setState({
            showModal : !current
        })
    }

  
    render(){

        let icon = 'warning'
        let text = "Fisierul a fost refuzat, va rugam reincarcati"
        let style = {color : "red"}

        if ( this.props.status === "confirmed"){
            icon = 'check-circle'
            text = "Fisierul a fost confirmat"
            style = {color : "green"}
        }
            

        if (this.props.status === "submit"){
            icon = 'question-circle2'
            text = "Fisierul este in curs de verificare"
            style = {color : "orange"}
        }
        if (this.props.status === "missing"){
            icon = 'question-circle2'
            text = "Fisierul nu a fost incarcat"
            style = {color : "orange"}
        }


        const tooltip = (
            <Tooltip>
               {text}
            </Tooltip>
            );

        return(
            <div className = "DocumentStatusLine-container">
                <div className = "DocumentStatusLine-content">
                    <Modal classNames={{
                        overlay: 'modal-overlay',
                        modal: 'onedocument2-modal',
                        closeButton: 'modal-close-button',
                        }} open={this.state.showModal} onClose={this.toggleModal} center>
                        <p> {this.props.name}</p>
                        <p> {this.props.comment} </p>
                    </Modal>


                    <p> {this.props.name} </p>
                    <Whisper placement="top" trigger="hover" speaker={tooltip}>
                        <Icon style = {style} id = "icon" icon={icon} />
                    </Whisper>
                    <Button onClick = {this.toggleModal} appearance = "ghost" > Detalii </Button>
                   
                </div>
            </div>
        )
    }
}

export default DocumentStatusLine;