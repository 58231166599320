import React from 'react'
import {Button, Nav, Icon} from 'rsuite'
import './stylesheets/Dashboard.scss'

//asets
import logo from '../../assets/logo.png'

//components
import Initial_Context from './components/Initial_Context'

import SignupLicenta from './components/SignupLicenta'
import SignupMaster from './components/SignupMasterat'
import SignupDoctorat from './components/SignupDoctorat'
import SignupOther from './components/SignupOther'

import Documents from './components/Documents'
import DocumentsMaster from './components/DocumentsMasterat'
import DocumentsDoctorat from './components/DocumentsDoctorat'
import DocumentsOther from './components/DocumentsOther'

import Main from './components/Main'
import axios from 'axios'
import {toast} from 'react-toastify'

class DashboardStudent extends React.Component{

    state = {
        activeTab : "home",
        initial : true,
        status : "initial",
        userInfo : {},
        loading : true,
        type : "Licenta"
    }

    handleNavChange = (value) => {

        this.setState({
            activeTab : value
        })
    }

    handleClick = () => {

        let current = this.state.signup_active;

        this.setState({
            signup_active : !current
        })
    }

    moveToDetalii = () => {
        console.log(this.state.type.toLowerCase())

        let id = this.state.type.toLowerCase()
        if ( id === "master")
            id = "masterat"
            
        this.setState({
            status : id
        })
    }
    componentDidMount() {

        console.log("hi")
        axios.get('/api/user/getUserInfo/' + localStorage.getItem('id'))
        .then ( response => {
            console.log(response)
            this.setState({
                loading : false,
                userInfo : response.data
            })

            axios.get('/api/submission/getType/' + localStorage.getItem('id'))
            .then ( response => {
                console.log(response)
                this.setState({
                    type : response.data.type,
                })
            })
            .catch( err => {

            })

            console.log(response);
        })
        .catch ( err => {

            console.log(err);
        })
    }

    moveToSignUp = (type) => {

        this.setState({
            status : type
        })
    }

    moveToDocumentsLicenta = () => {
        console.log("hello")
        console.log("licenta")
        this.setState({
            status : "documentsLicenta"
        })
    }

    moveToDocumentsMaster = () => {
        console.log("hello")
        this.setState({
            status : "documentsMaster"
        })
    }

    moveToDocumentsDoctorat = () => {
        console.log("hello")
        this.setState({
            status : "documentsDoctorat"
        })
    }

    moveToDocumentsOther = () => {
        console.log("hello Other")
        console.log(this.state.userInfo.has_submited_documents)
        this.setState({
            status : "documentsOther"
        })
    }

    logout = () => {
        toast.success("Delogat!")
        localStorage.clear('id') 
        localStorage.clear('jwt') 
        this.props.history.push('/login')
    }
    render() {

        if ( this.state.loading === true )
            return <p> Se incarca, va rugam asteptati. Pagina se va inchide automat cand documentele se vor incarca cu succes. Nu inchideti pagina! </p>

        let renderElement = null;
        // {(this.state.signup_active === false)? <Initial_Context handleClick = {this.handleClick} />: <Signup /> }

        if ( this.state.userInfo.has_submited_info === false && this.state.status === "initial" )
            renderElement = <Initial_Context prenume = {this.state.userInfo.prenume} changeStatus = {this.moveToSignUp}/>
      //  else
        if ( this.state.status === "licenta" )
            renderElement = <SignupLicenta moveToDocuments = {this.moveToDocumentsLicenta}/>
        else
        if ( this.state.status === "masterat" )
            renderElement = <SignupMaster moveToDocuments = {this.moveToDocumentsMaster}/>
        else
        if ( this.state.status === "doctorat" )
            renderElement = <SignupDoctorat moveToDocuments = {this.moveToDocumentsDoctorat}/>
        else
        if ( this.state.status === "other" )
            renderElement = <SignupOther moveToDocuments = {this.moveToDocumentsOther}/>
        else
        if ( (this.state.status === "documentsLicenta" || this.state.type === "Licenta") && (this.state.userInfo.has_submited_info === true && this.state.userInfo.has_submited_documents === false)){
            renderElement = <Documents />
            window.onscroll = function () { window.scrollTo(0, 0); };
        }
        else
        if ( (this.state.status === "documentsMaster" || this.state.type === "Master") && (this.state.userInfo.has_submited_info === true && this.state.userInfo.has_submited_documents === false)){
            renderElement = <DocumentsMaster />
            window.onscroll = function () { window.scrollTo(0, 0); };
        }
        else
        if ( (this.state.status === "documentsDoctorat" || this.state.type === "Doctorat") && (this.state.userInfo.has_submited_info === true && this.state.userInfo.has_submited_documents === false)){
            renderElement = <DocumentsDoctorat />
            window.onscroll = function () { window.scrollTo(0, 0); };
        }
        else
        if ( (this.state.status === "documentsOther" || this.state.type === "Other") && (this.state.userInfo.has_submited_info === true && this.state.userInfo.has_submited_documents === false)){
            renderElement = <DocumentsOther />
            window.onscroll = function () { window.scrollTo(0, 0); };
        }
        else
        if (  this.state.userInfo.has_submited_documents === true && this.state.status === "documentsLicenta")
           {
                renderElement = <Documents />
                window.onscroll = function () { window.scrollTo(0, 0); };
           }
           else
        if (  this.state.userInfo.has_submited_documents === true && this.state.status === "documentsMaster")
           {
                renderElement = <DocumentsMaster />
                window.onscroll = function () { window.scrollTo(0, 0); };
           }
        else
        if (  this.state.userInfo.has_submited_documents === true && this.state.status === "documentsDoctorat")
            {
                renderElement = <DocumentsDoctorat />
                window.onscroll = function () { window.scrollTo(0, 0); };
            }
        else
        if (  this.state.userInfo.has_submited_documents === true && this.state.status === "documentsOther")
            {
                renderElement = <DocumentsOther />
                window.onscroll = function () { window.scrollTo(0, 0); };
            }
        else
        if (  this.state.userInfo.has_submited_documents === true && this.state.type === "Licenta")
            renderElement = <Main moveToDetalii = {this.moveToDetalii} moveToDocuments = {this.moveToDocumentsLicenta} />
        else
        if (  this.state.userInfo.has_submited_documents === true && this.state.type === "Master")
            renderElement = <Main moveToDetalii = {this.moveToDetalii} moveToDocuments = {this.moveToDocumentsMaster} />
        else
            if (  this.state.userInfo.has_submited_documents === true && this.state.type === "Doctorat")
                renderElement = <Main moveToDetalii = {this.moveToDetalii} moveToDocuments = {this.moveToDocumentsDoctorat} />
        else
            if (  this.state.userInfo.has_submited_documents === true && this.state.type === "Other")
                renderElement = <Main moveToDetalii = {this.moveToDetalii} moveToDocuments = {this.moveToDocumentsOther} />
       
        // if ( this.state.status === "acte" )
            // renderElement = <Main />

        let widthOk = (window.innerWidth <= 765)?true:false;
        console.log(window.innerWidth)

        var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
        return(
            <div className = "DashboardStudent-container">
                <div className = "DashboardStudent-content">
                    <div style = {(iOS === true)? {backgroundColor:"white", marginBottom: "70px"}: null} className = "Dashboard-navbar">
                        <img style = {(iOS === true)? {marginTop:"60px"}: null} id = "logo" src = {logo} />
                        {(widthOk === true)? <Nav style = {(iOS === true)? {marginTop:"60px"}: null} reversed appearance="subtle" horizontal activeKey={this.state.activeTab} onSelect={this.handleNavChange} >
                            <Nav.Item eventKey="home" icon={<Icon icon="home" />}>
                            Home
                            </Nav.Item>
                            <Nav.Item onClick = {this.logout} eventKey="about">Log Out</Nav.Item>
                        </Nav>:<Nav  reversed appearance="subtle" vertical activeKey={this.state.activeTab} onSelect={this.handleNavChange} >
                            <Nav.Item eventKey="home" icon={<Icon icon="home" />}>
                            Home
                            </Nav.Item>
                            <Nav.Item onClick = {this.logout} eventKey="about">Log Out</Nav.Item>
                        </Nav>
                        
                    
                        }
                        
                    </div>
                    <div className = "Dashboard-container">
                        {renderElement}
                    </div>
                </div>
            </div>

        )
    }

}


export default DashboardStudent;