import React from 'react'
import './stylesheets/Main.scss'

//components
import ParticipantInfo from './Main/ParticipantInfo'
import News from './Main/News'
import Options from './Main/Options'

import DocumentStatus from './Main/DocumentStatus'

class Main extends React.Component{



    render(){

        return(
            <div className = "Main-container">
                <div className = "Main-content">
                    <div className = "Block-content">
                        <ParticipantInfo moveToDetalii = {this.props.moveToDetalii} />
                        <DocumentStatus moveToDocuments ={this.props.moveToDocuments} />
                    </div>
                    <div className = "Block-content">
                        <News />
                        <Options />
                    </div>
                   
                </div>
            </div>

        )
    }
}


export default Main;