import React from 'react'
import './stylesheets/News.scss'
import NewsElement from './components/NewsElement'
import Scrollbar from 'react-scrollbars-custom';

class News extends React.Component{

    status = {
        news : [
            // {title : "Pentru Ciclul I studii universitare", date : "17-Iul-2020", content : "Inchiderea platformei de iscriere in vederea admiterii la studii, sesiunea iulie 2020 va avea loc pentru ciclul I studii universitare de licenta, vineri 18.07.2020, ora 23.59; Solutionarea dosarelor de catre comisie si reincarcarea actelor de catre candidati, in conturile care au fost create pana la data si ora inchiderii platformei, se va putea efectua pana in data de 19.07.2020, ora 23.59; Afisarea rezultatelor partiale – dupa data de 21.07.2020. Confirmarea locurilor – din momentul afisarii rezultatelor, pana la data de 27.07.2020"},
            // {title : "Pentru Ciclul II studii universitare", date : "17-Iul-2020", content : "Inchiderea platformei de înscriere în vederea admiterii la studii, sesiunea iulie 2020 va avea loc pentru ciclul II studii universitare de masterat, vineri 17.07.2020, ora 23.59. Solutionarea dosarelor de catre comisie si reincarcarea actelor de catre candidati, in conturile care au fost create pana la data si ora inchiderii platformei, se va putea efectua pana in data de 18.07.2020, ora 23.59; Afisarea rezultatelor partiale – dupa data de 20.07.2020. Confirmarea locurilor – din momentul afisarii rezultatelor, pana la data de 27.07.2020"},
            // {title : "OPIS AL DOCUMENTELOR CONTRACTUALE", date : "21-Iul-2021", content : "mail: admitere_fefs@unefs.ro/admitere_fkt@unefs.ro,  Program : Luni -Vineri 10:00- 16:00"},

            {title : "Timpul de validare al documentelor încărcate este de 48h", date : "8-Iulie-2024", content : "mail: admitere_fefs@unefs.ro/admitere_fkt@unefs.ro,  Program : Luni - Vineri 9:00- 17:00"},
            // {title : "Verificarea notelor la examenul de bacalaureat in SIIIR", date : "05-Iunie-2021", content : "Verificarea notelor la examenul de bacalaureat in SIIIR, vor putea fi verificare după data de 10.07.2021"},

            // {title : "News Title", date : "12-Martie-2020", content : "New content is right here"},
            // {title : "News Title", date : "12-Martie-2020", content : "New content is right here"},
            // {title : "News Title", date : "12-Martie-2020", content : "New content is right here"},
            // {title : "News Title", date : "12-Martie-2020", content : "New content is right here"}
        ]
    }

    render(){

        let news = this.status.news.map( element => {

            return <NewsElement title = {element.title} date = {element.date} content = {element.content}/>
        })
        return(
           
                <div className = "News-container">
                    <Scrollbar style={{ width: "100%", height: "100%" }}>
                    <div className = "News-content">
                        
                            <h3> Anunturi si Proces Inscriere </h3>

                            {/* <div className = "NewsElement-container">
                                <div className = "NewsElement-content">
                                    <p id = "title"> OPIS AL DOCUMENTELOR CONTRACTUALE </p>
                                    <p id = "date"> 21-Iul-2021 </p>
                                    <p id = "content"> Documentele contractuale se vor descărca de pe site-ul UNEFS www.unefs.ro, accesând următoarele link-uri: <a href = "https://unefsb.ro/wp-content/uploads/2020/07/CONTESTATII-FEFS-LICENTA-SPM-FARA-EXAMEN-1.pdf" target = "_blank">aici</a> </p>
                                </div>
                            </div> */}
{/* 
                            <div className = "NewsElement-container">
                                <div className = "NewsElement-content">
                                    <p id = "title">OPIS AL DOCUMENTELOR CONTRACTUALE </p>
                                    <p id = "date"> 21-Iul-2021 </p>
                                    <p id = "content"> Documentele contractuale se vor descărca de pe site-ul UNEFS www.unefs.ro, accesând următoarele link-uri:</p>
                                    <br/>
                                    <p id = "content"><a href = "http://rest-unefs.syntract.ro/wp-content/uploads/2021/07/EFS02.pdf" target ="_blank"> PROGRAM DE STUDII - EDUCAȚIE FIZICĂ ȘI SPORTIVĂ (EFS) (aici) </a>  </p>
                                    <br/>
                                    <p id = "content"> <a href = "http://rest-unefs.syntract.ro/wp-content/uploads/2021/07/SPM02.pdf" target ="_blank"> PROGRAM DE STUDII - SPORT ȘI PERFORMANȚĂ MOTRICĂ, ÎNVĂȚĂMÂNT CU FRECVENȚĂ (SPM) (aici) </a> </p>
                                    <br/>
                                    <p id = "content"> <a href = "http://rest-unefs.syntract.ro/wp-content/uploads/2021/07/IFR02.pdf" target ="_blank"> PROGRAM DE STUDII - SPORT ȘI PERFORMANȚĂ MOTRICĂ, ÎNVĂȚĂMÂNT CU FRECVENȚĂ REDUSĂ (SPM-IFR) (aici) </a> </p>
                                    <br/>
                                    <p id = "content"> <a href = "http://rest-unefs.syntract.ro/wp-content/uploads/2021/07/MS02.pdf" target ="_blank"> PROGRAM DE STUDII - MANAGEMENT ÎN SPORT (MS) (aici) </a> </p>
                                
                                </div>
                            </div> */}

                            {/* <div className = "NewsElement-container">
                                <div className = "NewsElement-content">
                                    <p id = "title"> Pentru Ciclul I studii universitare </p>
                                    <p id = "date"> 17-Iul-2020 </p>
                                    <p id = "content"> Inchiderea platformei de inscriere in vederea admiterii la studii, sesiunea iulie 2020 va avea loc pentru ciclul I studii universitare de licenta, vineri 18.07.2020, ora 23.59; <br/> <br/> Solutionarea dosarelor de catre comisie si reincarcarea actelor de catre candidati, in conturile care au fost create pana la data si ora inchiderii platformei, se va putea efectua pana in data de 19.07.2020, ora 23.59;<br/> <br/> Afisarea rezultatelor partiale – dupa data de 21.07.2020. Confirmarea locurilor – din momentul afisarii rezultatelor, pana la data de 27.07.2020 </p>
                                </div>
                            </div>

                            <div className = "NewsElement-container">
                                <div className = "NewsElement-content">
                                    <p id = "title">Pentru Ciclul II studii universitare</p>
                                    <p id = "date"> 17-Iul-2020 </p>
                                    <p id = "content"> Inchiderea platformei de înscriere în vederea admiterii la studii, sesiunea iulie 2020 va avea loc pentru ciclul II studii universitare de masterat, vineri 17.07.2020, ora 23.59.<br/> <br/> Solutionarea dosarelor de catre comisie si reincarcarea actelor de catre candidati, in conturile care au fost create pana la data si ora inchiderii platformei, se va putea efectua pana in data de 18.07.2020, ora 23.59; <br/> <br/>Afisarea rezultatelor partiale – dupa data de 20.07.2020. Confirmarea locurilor – din momentul afisarii rezultatelor, pana la data de 27.07.2020 </p>
                                </div>
                            </div> */}

                            {news}
                    </div>
                    </Scrollbar>
                </div>
            
            
        )
    }
}

export default News;