import React from 'react'
import './stylesheets/SignupLogin.scss'
import {Input, Checkbox, Button, Divider, SelectPicker, DatePicker} from 'rsuite'
import Scrollbar from 'react-scrollbars-custom';
import Fade from 'react-reveal/Fade';
import {toast} from 'react-toastify'
import axios from 'axios'

class SignupLogin extends React.Component{

    state = {
        email : "",
        parola : "",
        parola2 : "",
        nume : "",
        prenume : "",
        sex : "",
        data_nasterii : "",
        cnp : "",
        gdpr : false,
        altCNPBool : false,
        altCNP : ""
    }

    handleChange = (value, event) => {

        this.setState({
            [event.target.name] : value
        })
    }

    handleSexSelect = (value) =>{
        this.setState({
            sex:  value
        })
    }

    handleDateSelect = (value) => {

        console.log(value);
        this.setState({
            data_nasterii : value
        }, () => {
            console.log(this.getNumFromData())
            console.log(this.getDataFromNum())
        })
    }

    parseMonth = (month) => {

        let dict = {
            "Jan" : "01",
            "Feb" : "02",
            "Mar" : "03",
            "Apr" : "04",
            "May" : "05",
            "Jun" : "06",
            "Jul" : "07",
            "Aug" : "08",
            "Sep" : "09",
            "Oct" : "10",
            "Nov" : "11",
            "Dec" : "12"
        }
        
        return dict[month];
    }
    getNumFromData = () => {
        console.log(this.state.data_nasterii.toString());
        let date = this.state.data_nasterii.toString().split(" ");

        return date[3].slice(2, 4) + this.parseMonth(date[1]) + date[2]
    }

    getDataFromNum = () => {

        let cnp = this.state.cnp;
        return cnp.slice(1, 7);
    }

    sendData = () => {


        // if ( this.getNumFromData() !== this.getDataFromNum() &&
        // (this.state.cnp[0] !== '1' || this.state.cnp[0] !== '2' || this.state.cnp[0] !== '5' || this.state.cnp[0] !== '6'))
        // {
        //     toast.error("CNP-ul si Data Nasterii nu sunt corelate")
        //     return;
        // }
        if ( this.state.cnp.length !== 13 && this.state.altCNPBool === false )
        {
            toast.error("CNP incorect")
            return;
        }

        if ( this.state.altCNPBool === true )
        {
            if ( this.state.altCNP === "" ){
                toast.error("Nu puteti lasa campul CNP Nou")
                return;
            }
            
        }

        if (!/^\s*(?:([A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4})\b\s*)+$/i.test(this.state.email)){
            toast.error("Email-ul introdus nu este corect scris");
            return;
        }

        if ( this.state.gdpr !== true ){

            toast.error("Nu ati bifat casuta de GDPR")
            return;
        }

        if ( this.state.parola !== this.state.parola2 ){

            toast.error("Parolele nu sunt identice")
            return;
        }

        if ( this.state.email === "" || this.state.parola === "" || this.state.parola2 === "" ||
        this.state.nume === "" || this.state.prenume === "" || this.state.sex === "" || this.state.data_nasterii === ""){
            toast.error("Nu ati completat toate campurile")
            return;
        }

        console.log( this.state);
        let obj = {
            nume : this.state.nume,
            prenume : this.state.prenume,
            sex : this.state.sex,
            data_nasterii : this.state.data_nasterii.toString(),
            email : this.state.email,
            cnp : (this.state.altCNPBool === true)? this.state.altCNP:this.state.cnp,
            password : this.state.parola,
            type : "student"
        }


        axios.post('/api/user/register', obj)
        .then ( response => {

            toast.success("Cont inregistrat cu succes!")
            console.log(response);

            let obj2 = {
                cnp : (this.state.altCNPBool === true)? this.state.altCNP:this.state.cnp,
                password : this.state.parola
            }
    
            axios.post('/api/user/login', obj2)
            .then ( response => {
    
                toast.success("Logare cu succes!")
                
                localStorage.setItem('jwt', response.data.token);
                localStorage.setItem('id', response.data.id);
                localStorage.setItem('type', response.data.type);
                
                if (response.data.type === "student")
                    this.props.history.push('/dashboardStudent')
                else 
                    if ( response.data.type === "admin")
                        this.props.history.push('/dashboardadmin')
    
                console.log(response);
            })
            .catch ( err => {
                toast.error(err.message)
                console.log(err.message);
            })
        })
        .catch ( err => {
            console.log(err);
            toast.error("Eroare la inscriere")
        })
    }

    componentDidMount() {

        let isAndroid = navigator.userAgent.toLowerCase().indexOf("android") > -1

		if (isAndroid){
			let viewHeight = window.innerHeight
			let viewport = document.querySelector("meta[name=viewport]");
			viewport.setAttribute("content", "height=" + viewHeight+ "px, width=device-width, initial-scale=1.0")
			//document.write('<meta name ="viewport" content="width=device-width, height=' + window.innerHeight+'initial-scale=1.0">');
		}
    }
    render(){

        return(
            <div className = "SignupLogin-container">
                <div className = "SignupLogin-content">
                    <div className = "top-info">
                        <h3> Inscriere in platforma </h3>
                        <Divider />
                    </div>
                    
                    <div className = "Input-element" >
                        <p> CNP </p>
                        <Input className ="input" name = "cnp" onChange = {this.handleChange} placeholder = "Folosit pentru logare" />
                    </div>

                    <div className = "Input-element" >
                        <Checkbox style = {{fontSize: "17px"}} checked = {this.state.altCNPBool} onChange = {(value, checked) => this.setState({altCNPBool : checked})}> Exclusiv pentru cetatenii de alta nationalitate </Checkbox>
                    </div>

                    {this.state.altCNPBool === true?<div className = "Input-element">
                        <p> CNP-ul diferit </p>
                        <Input className ="input" name = "altCNP" onChange = {this.handleChange} placeholder = "Exclusiv pentru cetatenii de alta nationalitate" />
                    </div>:null}

                    <div className = "Input-element" >
                        <p> Email </p>
                        <Input className ="input" name = "email" onChange = {this.handleChange} placeholder = "adresa@mail.ro" />
                    </div>

                    <div className = "Input-element" >
                        <p> Parola </p>
                        <Input className ="input" name = "parola" onChange = {this.handleChange} type = "password" placeholder = "*******"/>
                    </div>

                    <div className = "Input-element" >
                        <p> Repeta Parola </p>
                        <Input className ="input" name = "parola2" onChange = {this.handleChange} type = "password" placeholder = "*******" />
                    </div>

                    <div className = "Input-element" >
                        <p> Nume </p>
                        <Input className ="input" name = "nume" onChange = {this.handleChange} type = "text" />
                    </div>
                    
                    <div className = "Input-element" >
                        <p> Prenume </p>
                        <Input className ="input" name = "prenume" onChange = {this.handleChange} type = "text"/>
                    </div>


                    <div className = "Input-element" >
                        <p> Sex </p>
                        <SelectPicker id ="input" data = {[{label : "Masculin", value : "Masculin"}, {label: "Feminin", value : "Feminin"}]} onSelect = {this.handleSexSelect} searchable = {false} placeholder = "Alege o Optiune" block />
                    </div>

                    <div className = "Input-element" >
                        <p> Data Nasterii </p>
                        <DatePicker  id ="input" placement="topStart" onSelect = {this.handleDateSelect} placeholder = "Alege Data Nasterii" block />
                    </div>

                    <div className = "Input-element" >
                        <p id = "declar"> SUNT DE ACORD CU <a target = "blank" href = "http://admitere.unefs.ro/wp-content/uploads/2022/03/FEFS-ANEXA-2a-GDPR.pdf"> POLITICA DE CONFIDENTIALITATE IMPUSE DE UNEFS </a> </p>
                        <Checkbox value = {this.state.gdpr} onChange = {(value, checked) => this.setState({gdpr : checked})}/>
                    </div>


                    <div className = "footer">
                        <Button className = "submit-button" onClick = {this.sendData} appearance = "primary" > Ma inscriu </Button>
                        <p> In caz de probleme tehnice, va rugam sa ne contactacti la admitere_fefs@unefs.ro/admitere_fkt@unefs.ro </p>
                    </div>

                    

                </div>

            </div>
        )
    }
}


export default SignupLogin;