import React from 'react'
import './stylesheets/ConfirmEmail.scss'
import {Button, Input} from 'rsuite'
import logo from '../assets/logo.png'

class ConfirmEmail extends React.Component{

    backToLogin = () => {

        this.props.history.push('/login')
    }
    
    render(){
        return(
            <div className = "ConfirmEmail-container">
                <div className = "ConfirmEmail-content">
                    <div className = "ConfirmEmail-top">
                        <img id = "logo" src = {logo} />
                        <h2> Modul Inscrieri UNEFS </h2>
                        <p> Contul tau a fost activat! </p>
                        <Button onClick = {this.backToLogin} appearance = "primary" > Inapoi la Login </Button>
                    </div>
                </div>
            </div>

        )
    }

}

export default ConfirmEmail;