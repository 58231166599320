import React from 'react'
import './stylesheets/Documents.scss'
import {Divider, Uploader, Button, Slider } from 'rsuite'
import axios from 'axios'
import { toast } from 'react-toastify'
import Modal from 'react-responsive-modal'
import Cropper from 'react-easy-crop'
import getCroppedImg from './cropImage'

class Documents extends React.Component{

    state = {
        file_poza : null,
        file_certificatNastere : null,
        file_carteIdentitate : null,
        file_foaieMatricola : null,
        file_foaieMatricola2 : null,

        file_certificatCasatorie : null,
        file_adeverintaBac : null,
        file_acordSIR : null,
        
        file_dovada : null,
        file_cerereInscriere : null,
        file_cerereInscriere2 : null,

        file_gdpr : null,
        file_gdpr2 : null,

        file_consultatii : null,
        file_consultatii2 : null,
        file_bac : null,
        file_bac_2 : null,
        file_douaFacultati : null,
        file_douaFacultati2 : null,
        file_douaFacultati3 : null,

        file_rrom1 : null,
        file_dizabilitati : null,

        file_rrom2 : null,
        file_rrom3 : null,
        file_sport1 : null,
        file_sport2 : null,
        file_limba : null,
        file_dovada2 : null,
        nr_documents : "",
        loading : false,
        showModal : false,
        image: 'your-image-url or as base64',
        crop: { x: 0, y: 0 },
        zoom: 1,
        aspect: 3 / 4,
        rotation: 0,
        base64data : "",
        croppedArea : "",
        firstLoad : true,
        subInfo : "",
        rejectedDocs : []
    }

    async componentDidMount(){

        let userPromise = await axios.get('/api/user/getUserInfo/' + localStorage.getItem('id'));

        console.log(userPromise)
        this.setState({
            firstLoad : (userPromise.data.has_submited_documents === true)?false:true
        })

        let submissionData = await axios.get('/api/submission/getInfo/' + localStorage.getItem('id'));

        console.log(submissionData.data.optionList)
        this.setState({
            subInfo : submissionData.data
        })

        let rejectDocs = []
        if (submissionData.data !== "" && typeof submissionData.data !== null){

            for ( var i = 0; i< submissionData.data.documents.length; i++ ){
                if ( submissionData.data.documents[i].status === "reject" || submissionData.data.documents[i].status === "missing" ){
                    rejectDocs.push (submissionData.data.documents[i].file)
                }
                
            }
            this.setState({
                rejectedDocs: rejectDocs
            })
        }

    }

    onCropChange = crop => {
        this.setState({ crop })
      }

    onCropComplete = (croppedArea, croppedAreaPixels) => {
        console.log(croppedArea)
        console.log(croppedAreaPixels)
        this.setState({
            croppedArea : croppedAreaPixels
        })
      }

    showCroppedImage = async () => {

        try {
          const croppedImage = await getCroppedImg(
            this.state.base64data,
            this.state.croppedArea,
            this.state.rotation
          )
          console.log('donee', { croppedImage })
          this.setState({file_poza : croppedImage.croppedImage })
        } catch (e) {
          console.error(e)
        }

      }

    
      onZoomChange = zoom => {
        this.setState({ zoom })
      }

      setRotation = rotation => {
        this.setState({ rotation })
      }
    handlePoza = async (fileList) => {
    
        if ( fileList.length >0 && fileList[0].blobFile.name.indexOf(".jpeg") > 0){

            toast.error("Poza poate fi doar in format .jpg")
            return;
        }
        if ( fileList.length > 0 && fileList[0].blobFile.type !== 'image/jpeg'){
            toast.error("Poza poate fi doar in format .jpg")
            return;
        }

        if ( fileList.length > 0){
            var reader = new FileReader();
            reader.readAsDataURL(fileList[0].blobFile); 
            var base64data = null;
            reader.onloadend =  () => {
                base64data = reader.result;                
                console.log(base64data)
                this.setState({
                    file_poza : fileList[0].blobFile,
                    base64data : base64data
                }, () => {
                    // this.toggleModal();
                })
            }
        }
        

        

        
    }


    handleAcordSIR = (fileList) => {

        if(fileList[0].blobFile.type !== "application/pdf")
        {
            toast.error("Nu puteti incarca fisiere de tip diferit de PDF")
            return;
        }

        if( fileList.length > 0)
        this.setState({
            file_acordSIR : fileList[0].blobFile
        })

    }

    handleAdeverintaBac = (fileList) => {

        if(fileList[0].blobFile.type !== "application/pdf")
        {
            toast.error("Nu puteti incarca fisiere de tip diferit de PDF")
            return;
        }

        if( fileList.length > 0)
        this.setState({
            file_adeverintaBac : fileList[0].blobFile
        })

    }

    handleFoaieMatricola = (fileList) => {

        if(fileList[0].blobFile.type !== "application/pdf")
        {
            toast.error("Nu puteti incarca fisiere de tip diferit de PDF")
            return;
        }

        if( fileList.length > 0)
        this.setState({
            file_foaieMatricola : fileList[0].blobFile
        })

        if( fileList.length > 1)
        this.setState({
            file_foaieMatricola2 : fileList[1].blobFile
        })

    }
    handleLimba = (fileList) => {
    
        if(fileList[0].blobFile.type !== "application/pdf")
        {
            toast.error("Nu puteti incarca fisiere de tip diferit de PDF")
            return;
        }

        if( fileList.length > 0)
        this.setState({
            file_limba : fileList[0].blobFile
        })
    }

    handleBAC = (fileList) => {
    
        if(fileList[0].blobFile.type !== "application/pdf")
        {
            toast.error("Nu puteti incarca fisiere de tip diferit de PDF")
            return;
        }

        if( fileList.length > 0)
        this.setState({
            file_bac : fileList[0].blobFile
        })

        if( fileList.length > 1)
        this.setState({
            file_bac_2 : fileList[1].blobFile
        })
    }

    handleCarteIdentitate = (fileList) => {
    
        // console.log(fileList[0].blobFile);
        if(fileList[0].blobFile.type !== "application/pdf")
        {
            toast.error("Nu puteti incarca fisiere de tip diferit de PDF")
            return;
        }

        if( fileList.length > 0)
        this.setState({
            file_carteIdentitate : fileList[0].blobFile
        })
    }

    handleCasatorie = (fileList) => {
    
        if(fileList[0].blobFile.type !== "application/pdf")
        {
            toast.error("Nu puteti incarca fisiere de tip diferit de PDF")
            return;
        }

        if( fileList.length > 0)
        this.setState({
            file_certificatCasatorie : fileList[0].blobFile
        })
    }

    handleCertificatNastere = (fileList) => {
    
        console.log(fileList[0].blobFile.type);
        if(fileList[0].blobFile.type !== "application/pdf")
        {
            toast.error("Nu puteti incarca fisiere de tip diferit de PDF")
            return;
        }

        if( fileList.length > 0)
        this.setState({
            file_certificatNastere : fileList[0].blobFile
        })
    }

    handleInscriere2 = (fileList) => {
    
        if(fileList[0].blobFile.type !== "application/pdf")
        {
            toast.error("Nu puteti incarca fisiere de tip diferit de PDF")
            return;
        }

        if( fileList.length > 0)
        this.setState({
            file_cerereInscriere2 : fileList[0].blobFile
        })
    }

    handleFacultate = (fileList) => {
    
        if(fileList[0].blobFile.type !== "application/pdf")
        {
            toast.error("Nu puteti incarca fisiere de tip diferit de PDF")
            return;
        }

        if( fileList.length > 0)
        this.setState({
            file_douaFacultati : fileList[0].blobFile
        })
    }

    handleFacultate2 = (fileList) => {
    
        if(fileList[0].blobFile.type !== "application/pdf")
        {
            toast.error("Nu puteti incarca fisiere de tip diferit de PDF")
            return;
        }

        if( fileList.length > 0)
        this.setState({
            file_douaFacultati2 : fileList[0].blobFile
        })

        
    }

    handleFacultate3 = (fileList) => {
    
        if(fileList[0].blobFile.type !== "application/pdf")
        {
            toast.error("Nu puteti incarca fisiere de tip diferit de PDF")
            return;
        }

        if( fileList.length > 0)
        this.setState({
            file_douaFacultati3 : fileList[0].blobFile
        })

    }


    handleFisaMedicala = (fileList) => {
    
        if(fileList[0].blobFile.type !== "application/pdf")
        {
            toast.error("Nu puteti incarca fisiere de tip diferit de PDF")
            return;
        }

        if( fileList.length > 0)
        this.setState({
            file_consultatii : fileList[0].blobFile
        })

        if( fileList.length > 1)
        this.setState({
            file_consultatii2 : fileList[1].blobFile
        })
    }

    handleGDPR = (fileList) => {
    
        if(fileList[0].blobFile.type !== "application/pdf")
        {
            toast.error("Nu puteti incarca fisiere de tip diferit de PDF")
            return;
        }

        if( fileList.length > 0)
        this.setState({
            file_gdpr : fileList[0].blobFile
        })

        if( fileList.length > 1)
        this.setState({
            file_gdpr2 : fileList[1].blobFile
        })
    }

    handleInscriere = (fileList) => {
    
        if(fileList[0].blobFile.type !== "application/pdf")
        {
            toast.error("Nu puteti incarca fisiere de tip diferit de PDF")
            return;
        }

        if( fileList.length > 0)
        this.setState({
            file_cerereInscriere : fileList[0].blobFile
        })
    }

    handleRrom1 = (fileList) => {
    
        if(fileList[0].blobFile.type !== "application/pdf")
        {
            toast.error("Nu puteti incarca fisiere de tip diferit de PDF")
            return;
        }

        if( fileList.length > 0)
        this.setState({
            file_rrom1 : fileList[0].blobFile
        })
    }

    handleDizabilitati = (fileList) => {
    
        if(fileList[0].blobFile.type !== "application/pdf")
        {
            toast.error("Nu puteti incarca fisiere de tip diferit de PDF")
            return;
        }

        if( fileList.length > 0)
        this.setState({
            file_dizabilitati : fileList[0].blobFile
        })
    }

    handleRrom2 = (fileList) => {
    
        if(fileList[0].blobFile.type !== "application/pdf")
        {
            toast.error("Nu puteti incarca fisiere de tip diferit de PDF")
            return;
        }

        if( fileList.length > 0)
        this.setState({
            file_rrom2 : fileList[0].blobFile
        })
    }

    handleRrom3 = (fileList) => {
    
        if(fileList[0].blobFile.type !== "application/pdf")
        {
            toast.error("Nu puteti incarca fisiere de tip diferit de PDF")
            return;
        }

        if( fileList.length > 0)
        this.setState({
            file_rrom3 : fileList[0].blobFile
        })
    }


    handleSport1 = (fileList) => {
    
        if(fileList[0].blobFile.type !== "application/pdf")
        {
            toast.error("Nu puteti incarca fisiere de tip diferit de PDF")
            return;
        }

        if( fileList.length > 0)
        this.setState({
            file_sport1 : fileList[0].blobFile
        })
    }

    handleSport2 = (fileList) => {
    
        if(fileList[0].blobFile.type !== "application/pdf")
        {
            toast.error("Nu puteti incarca fisiere de tip diferit de PDF")
            return;
        }

        if( fileList.length > 0)
        this.setState({
            file_sport2 : fileList[0].blobFile
        })
    }

    handleDovada = (fileList) => {
    
        if(fileList[0].blobFile.type !== "application/pdf")
        {
            toast.error("Nu puteti incarca fisiere de tip diferit de PDF")
            return;
        }

        if( fileList.length > 0)
        this.setState({
            file_dovada : fileList[0].blobFile
        })

        if( fileList.length > 1)
        this.setState({
            file_dovada2 : fileList[1].blobFile
        })
    } 

    toggleModal = (name) => {

        
        let current = this.state.showModal;

        this.setState({
            showModal : !current,
        })
    }

    sendData = () => {

        if ( this.state.firstLoad === true ){

            // if ( this.state.subInfo.optionList.includes("Sport si Performanta Motrica - Sportivi de performanta - fara examen") === true){
            //     if ( this.state.file_sport1 === null ){
            //         toast.error("Nu ati adeverinta de performanta in sport")
            //         return;
            //     }
            // }
            // if ( this.state.file_dovada === null )
            // {
            //     toast.error("Nu ati adaugat Dovada de Plata")
            //     return;
            // }
            // if ( this.state.file_certificatNastere === null )
            // {
            //     toast.error("Nu ati adaugat Certificatul de Nastere")
            //     return;
            // }
            // if ( this.state.file_carteIdentitate === null )
            // {
            //     toast.error("Nu ati adaugat Copie dupa CI")
            //     return;
            // }
            // // if ( this.state.file_poza === null )
            // // {
            // //     toast.error("Nu ati adaugat Poza de Legitimatie")
            // //     return;
            // // }

            // if ( this.state.subInfo.studii_univ !== "Nu am mai fost inscris la alta facultate" ){
            //     if ( this.state.file_douaFacultati === null )
            //     {
            //         toast.error("Nu ati adaugat adeverinta prin care reiese regimul de finantare")
            //         return;
            //     }
            // }
            // if (this.state.subInfo.studii_univ === "Am absolvit alta facultate" ){
            //     if ( this.state.file_douaFacultati2 === null )
            //     {
            //         toast.error("Nu ati adaugat diploma de licenta")
            //         return;
            //     }
            //     if ( this.state.file_douaFacultati3 === null )
            //     {
            //         toast.error("Nu ati adaugat suplimentul diplomei de licenta")
            //         return;
            //     }
                
            // }

            // if ( this.state.subInfo.ambeleFac === true ){
            //     if ( this.state.file_cerereInscriere2 === null )
            //     {
            //         toast.error("Nu ati adaugat Cererea de Inscriere pentru facultatea a doua")
            //         return;
            //     }
            // }
            
            // if ( this.state.file_cerereInscriere === null )
            // {
            //     toast.error("Nu ati adaugat Cererea de Inscriere")
            //     return;
            // }
            // if ( this.state.file_gdpr === null )
            // {
            //     toast.error("Nu ati adaugat consimțământului în vederea prelucrării datelor cu caracter personal")
            //     return;
            // }
            // if ( this.state.file_consultatii === null )
            // {
            //     toast.error("Nu ati adaugat fisa de consultatii medicale ")
            //     return;
            // }
            // if ( this.state.file_bac === null )
            // {
            //     toast.error("Nu ati adaugat Copie dupa Diploma de BAC")
            //     return;
            // }

            // if ( this.state.subInfo.optionList.includes("Sport si Performanta Motrica - IFR") === false && this.state.file_foaieMatricola === null )
            // {
            //     toast.error("Nu ati adaugat Foaia Matricola")
            //     return;
            // }

            // if ( this.state.subInfo.ambeleFac === true ){
            //     if ( this.state.file_cerereInscriere2 === null )
            //     {
            //         toast.error("Nu ati adaugat Adeverinta pentru doua facultati simultan")
            //         return;
            //     }
            // }

            // if ( this.state.subInfo.rrom === true ){
            //     if ( this.state.file_rrom1 === null )
            //     {
            //         toast.error("Nu ati adaugat Recomandarea scrisă din partea preşedintelui unei organizaţii legal constituite a rromilor ")
            //         return;
            //     }
            //     if ( this.state.file_rrom2 === null )
            //     {
            //         toast.error("Nu ati adaugat Copie legalizată (sau conform cu originalul) a hotărârii judecătoreşti care atestă constituirea legală a organizaţiei respective")
            //         return;
            //     }
            //     if ( this.state.file_rrom3 === null )
            //     {
            //         toast.error("Nu ati adaugat Declaraţie pe proprie răspundere că respectivul candidat face parte din etnia rromă")
            //         return;
            //     }
            // }
            
            // if ( this.state.subInfo.optionList.includes("Sport si Performanta Motrica - Zi - Engleza") === true ){
            //     if ( this.state.file_limba === null )
            //     {
            //         toast.error("Nu ati adaugat Atestatul de limba")
            //         return;
            //     }

            // }

            // if ( this.state.subInfo.optionList.includes("Sport si Performanta Motrica - IFR") === true ||
            // this.state.subInfo.optionList.includes("Sport si Performanta Motrica - Sportivi de performanta - fara examen") === true ){
            //     if ( this.state.file_sport1 === null )
            //     {
            //         toast.error("Nu ati adaugat Adeverinţă eliberată de federaţia de specialitate")
            //         return;
            //     }
            //     // if ( this.state.file_sport2 === null )
            //     // {
            //     //     toast.error("Nu ati adaugat copie legalizată sau conform cu originalul după diploma obţinută")
            //     //     return;
            //     // }
            // }
        }
        

        const fd = new FormData();
        console.log(this.state.file_fisaMedicala)

        // if ( this.state.file_acordSIR !== null )
        // {
        //     fd.append('acord_siiir', this.state.file_acordSIR, this.state.file_acordSIR.name);
        // }

        // if ( this.state.file_adeverintaBac !== null )
        // {
        //     fd.append('adeverinta_bac', this.state.file_adeverintaBac, this.state.file_adeverintaBac.name);
        // }

        if ( this.state.file_douaFacultati3 !== null )
        {
            fd.append('supliment_diploma_licenta', this.state.file_douaFacultati3, this.state.file_douaFacultati3.name);
        }
        if ( this.state.file_foaieMatricola2 !== null )
        {
            fd.append('foaie_matricola_pag2', this.state.file_foaieMatricola2, this.state.file_foaieMatricola2.name);
        }

        if ( this.state.file_gdpr2 !== null )
        {
            fd.append('document_gdpr_pag2', this.state.file_gdpr2, this.state.file_gdpr2.name);
        }


        if ( this.state.file_douaFacultati2 !== null )
        {
            fd.append('diploma_licenta', this.state.file_douaFacultati2, this.state.file_douaFacultati2.name);
        }

        if ( this.state.file_dovada2 !== null )
        {
            fd.append('dovada_plata2', this.state.file_dovada2, this.state.file_dovada2.name);
        }

        if ( this.state.file_dovada !== null )
        {
            fd.append('dovada_plata', this.state.file_dovada, this.state.file_dovada.name);
        }

        if ( this.state.file_foaieMatricola !== null )
        {
            fd.append('foaie_matricola', this.state.file_foaieMatricola, this.state.file_foaieMatricola.name);
        }

        if ( this.state.file_bac !== null )
        {
            fd.append('document_bac', this.state.file_bac, this.state.file_bac.name);
        }

        if ( this.state.file_bac_2 !== null )
        {
            fd.append('document_bac_2', this.state.file_bac_2, this.state.file_bac_2.name);
        }
        if ( this.state.file_certificatCasatorie !== null )
        {
            fd.append('certificat_casatorie', this.state.file_certificatCasatorie, this.state.file_certificatCasatorie.name);
        }
        if ( this.state.file_carteIdentitate !== null )
        {
            fd.append('carte_identitate', this.state.file_carteIdentitate, this.state.file_carteIdentitate.name);
        }
        if ( this.state.file_cerereInscriere !== null )
        {      
            fd.append('cerere_inscriere', this.state.file_cerereInscriere, this.state.file_cerereInscriere.name);
        }

        if ( this.state.file_cerereInscriere2 !== null )
        {      
            fd.append('cerere_inscriere2', this.state.file_cerereInscriere2, this.state.file_cerereInscriere2.name);
        }
        if ( this.state.file_poza !== null )
        {      
            fd.append('poza_legitimatie', this.state.file_poza, 'test.png');
        }
        if ( this.state.file_certificatNastere !== null )
        {      
            fd.append('certificat_nastere', this.state.file_certificatNastere, this.state.file_certificatNastere.name);
        }
        if ( this.state.file_consultatii !== null )
        {      
            fd.append('fisa_consultatii', this.state.file_consultatii, this.state.file_consultatii.name);
        }
        if ( this.state.file_consultatii2 !== null )
        {      
            fd.append('fisa_consultatii2', this.state.file_consultatii2, this.state.file_consultatii2.name);
        }
        if ( this.state.file_douaFacultati !== null )
        {      
            fd.append('adeverinta_doua_facultati', this.state.file_douaFacultati, this.state.file_douaFacultati.name);
        }
        if ( this.state.file_gdpr !== null )
        {      
            fd.append('document_gdpr', this.state.file_gdpr, this.state.file_gdpr.name);
        }
        if ( this.state.file_rrom1 !== null )
        {      
            fd.append('recomandare_scrisa_r1', this.state.file_rrom1, this.state.file_rrom1.name);
        }
        if ( this.state.file_dizabilitati !== null )
        {      
            fd.append('adeverinta_dizabilitati', this.state.file_dizabilitati, this.state.file_dizabilitati.name);
        }
        if ( this.state.file_rrom2 !== null )
        {      
            fd.append('copie_legalizata_r2', this.state.file_rrom2, this.state.file_rrom2.name);
        }
        if ( this.state.file_rrom3 !== null )
        {      
            fd.append('declaratie_raspundere_r3', this.state.file_rrom3 , this.state.file_rrom3.name);
        }
        if ( this.state.file_sport1 !== null )
        {      
            fd.append('adeverinta_federatie_s1', this.state.file_sport1 , this.state.file_sport1.name);
        }
        if ( this.state.file_sport2 !== null )
        {      
            fd.append('diploma_competitie_s2', this.state.file_sport2 , this.state.file_sport2.name);
        }
        if ( this.state.file_limba !== null )
        {      
            fd.append('atestat_limba', this.state.file_limba , this.state.file_limba.name);
        }

       
    
        this.setState({
            loading : true
        })

        axios.post('/api/submission/submitDocuments/' + localStorage.getItem('id'), fd)
        .then(response => {

            console.log(response)

            axios.get('/api/submission/getNrDocuments/' + localStorage.getItem('id'))
            .then( response => {
                console.log(response);

                this.setState({
                    nr_documents : response.data,
                    loading : false
                })

                if ( response.data >= 1 ){
                    toast.success("Incarcare cu succes!")
                    window.location.reload();
                }
                else{
                    window.location.reload();
                    toast.error("Eroare la incarcare")
                }
            })
        })

    }

    render(){

        const styles = {
            lineHeight: '200px'
          };
          if ( this.state.loading === true) 
            return  <p> Se incarca, va rugam asteptati. Nu inchideti pagina! </p>

        if ( this.state.subInfo === "")
            return  <p> Se incarca, va rugam asteptati. Nu inchideti pagina! </p>

        return(
            <div className = "Documents-container">
                <div className = "Documents-content">

                    <Modal classNames={{
                        overlay: 'modal-overlay',
                        modal: 'onedocument-modal',
                        closeButton: 'modal-close-button',
                        }} open={this.state.showModal} onClose={this.toggleModal} center>
                            <p style = {{marginTop: "20x"}}> Va rugam taiati fotografia astfel incat sa fie vizibila fata dumneavoastra </p>
                            <div className="crop-container">
                                <Cropper
                                    image={this.state.base64data}
                                    crop={this.state.crop}
                                    zoom={this.state.zoom}
                                    rotation = {this.state.rotation}
                                    aspect={this.state.aspect}
                                    onZoomChange = {this.onZoomChange}
                                    onCropChange={this.onCropChange}
                                    onCropComplete={this.onCropComplete}
                                    onRotationChange={this.setRotation}
                                />
                            </div>
                            <p> Rotatie </p>
                            <Slider
                                step = {90}
                                max = {360}
                                min = {0}
                                progress 
                                graduated
                                // value = {this.state.rotation}
                                defaultValue={0}
                                onChange={value => {
                                    this.setRotation(value)
                                }}
                            />
                            <p style = {{marginTop: "30px"}}> Zoom </p>
                            <Slider
                                min = {1}
                                max = {5}
                                progress 
                                graduated
                                // value = {this.state.zoom}
                                defaultValue={1}
                                onChange={value => {
                                    this.onZoomChange(value)
                                }}
                            />
                            <Button onClick = {this.showCroppedImage} appearance = "primary" style = {{marginTop: "30px"}}>
                                Accept Fotografie
                            </Button>
                    </Modal>





                    <h1> Documente de inscriere </h1>
                    <h6> <a href = "http://admitere.unefs.ro" target = "_blank"> Link catre Brosurile de Admitere </a></h6>

                    <h6> <strong>!IMPORTANT:</strong> Rugam canditatii sa incarce aceste documente de pe calculator/laptop </h6>
                    {this.state.firstLoad === false?<p>Sunteti in modul de re-upload. Nu trebuie sa urcati toate documentele. Va rugam uploadati doar documentul/documentele refuzate de catre comisie si apoi apasati pe butonul abastru de "Adauga documente" din josul paginii</p>:null}


                    <Divider />

                    {/* {this.state.firstLoad === true || this.state.rejectedDocs.includes("poza_legitimatie.jpg") === true?<div className = "Upload-zone">
                        <h3> Poza legitimatie </h3>
                        <p> Fisiere acceptate: .jpg, Fotografie tip buletin: 3x4cm, maximum 5MB</p>
                        <Uploader onChange = {this.handlePoza} accept = ".jpg" autoUpload = {false} dragable>
                            <div style={styles}>Apasa sau Adauga fisierele aici pentru upload </div>
                        </Uploader>
                    </div>:null} */}

                    {this.state.firstLoad === true || this.state.rejectedDocs.includes("certificat_nastere.pdf") === true?<div className = "Upload-zone">
                        <h3> Certificat de Nastere </h3>
                        <p> Fisiere acceptate: .pdf, maximum 5MB</p>
                        <Uploader onChange = {this.handleCertificatNastere} accept = ".pdf" autoUpload = {false} dragable>
                            <div style={styles}>Apasa sau Adauga fisierele aici pentru upload </div>
                        </Uploader>
                    </div>:null}

                    {this.state.firstLoad === true || this.state.rejectedDocs.includes("carte_identitate.pdf") === true?<div className = "Upload-zone">
                        <h3> Cartea de Identitate </h3>
                        <p> Fisiere acceptate: .pdf, maximum 5MB</p>
                        <Uploader onChange = {this.handleCarteIdentitate} accept = ".pdf" autoUpload = {false} dragable>
                            <div style={styles}>Apasa sau Adauga fisierele aici pentru upload </div>
                        </Uploader>
                    </div>:null}
                    
                    {this.state.firstLoad === true || this.state.rejectedDocs.includes("document_bac.pdf") === true || this.state.rejectedDocs.includes("document_bac_2.pdf") === true?<div className = "Upload-zone">
                        <h3> Diploma de bacalaureat (copie fata-verso).  
                        </h3>
                        <h4>  Recunoasterea si echivalarea actelor de studii de nivel preuniversitar obtinute în strainatate, de catre CNRED, daca este cazul. </h4>
                        <h4> Adeverinta eliberata de către institutia de invatamant din care sa reiasa nota obtinuta la examenul de bacalaureat, doar pentru absolventii din promotia 2022 </h4>
                        <h4> Acord privind posibilitatea verficarii in Sistemul informatic integrat al invatamantului din Romania (SIIIR) a notei obtinute la examenul de bacalaureat, doar pentru promotia 2023 ( <a target = "_blank" href="http://admitere.unefs.ro/wp-content/uploads/2022/06/Acord-privind-posibilitatea-verficarii-in-Sistemul-informatic-integrat-al-invatamantului-din-Romania.pdf">descarca aici</a>) </h4>

                        <p> Fisiere acceptate: .pdf, maximum 5MB</p>
                        <Uploader onChange = {this.handleBAC} accept = ".pdf" autoUpload = {false} dragable>
                            <div style={styles}>Apasa sau Adauga fisierele aici pentru upload </div>
                        </Uploader>
                    </div>:null}
                    

                    {(this.state.firstLoad === true || this.state.rejectedDocs.includes("foaie_matricola.pdf") || this.state.rejectedDocs.includes("foaie_matricola_pag2.pdf") === true)?<div className = "Upload-zone">
                        <h3> Foaia Matricola clasele IX-XII sau Adeverinta eliberata de catre institutia de invatamant din care sa reiasa nota obtinuta la disciplina Educatie Fizica si Sport/ Pregatire sportiva practica   </h3>
                        <p> Fisiere acceptate: .pdf, maximum 5MB</p>
                        <Uploader onChange = {this.handleFoaieMatricola} accept = ".pdf" autoUpload = {false} dragable>
                            <div style={styles}>Apasa sau Adauga fisierele aici pentru upload </div>
                        </Uploader>
                    </div>:null}

                    {this.state.firstLoad === true || this.state.rejectedDocs.includes("dovada_plata.pdf") === true?<div className = "Upload-zone">
                        <h3> Dovada platii taxei de inscriere/ Dovada scutirii de la plata taxei </h3>
                        <p> Fisiere acceptate: .pdf, maximum 5MB </p>
                        <Uploader onChange = {this.handleDovada} accept = ".pdf" autoUpload = {false} dragable>
                            <div style={styles}>Apasa sau Adauga fisierele aici pentru upload </div>
                        </Uploader>
                    </div>:null}

                    {/* <div className = "Upload-zone">
                        <h3> Adeverinta eliberata de catre institutia de invatamant din care sa reiasa nota obtinuta la examenul de bacalaureat, doar pentru absolventii din promotia 2020 </h3>
                        <p> Fisiere acceptate: .pdf, maximum 5MB</p>
                        <Uploader onChange = {this.handleAdeverintaBac} accept = ".pdf" autoUpload = {false} dragable>
                            <div style={styles}>Apasa sau Adauga fisierele aici pentru upload </div>
                        </Uploader>
                    </div> */}

                    {/* <div className = "Upload-zone">
                        <h3> Acord privind posibilitatea verificarii in Sistemul informatic integrat al invatamantului din Romania (SIIIR) a notei obtinute la examenul de bacalaureat, doar pentru promotia 2020 </h3>
                        <p> Fisiere acceptate: .pdf, maximum 5MB</p>
                        <Uploader onChange = {this.handleAcordSIR} accept = ".pdf" autoUpload = {false} dragable>
                            <div style={styles}>Apasa sau Adauga fisierele aici pentru upload </div>
                        </Uploader>
                    </div> */}

                    

                    {this.state.firstLoad === true || this.state.rejectedDocs.includes("certificat_casatorie.pdf") === true?<div className = "Upload-zone">
                        <h3> Certificat de Casatorie (daca este cazul)</h3>
                        <p> Fisiere acceptate: .pdf, maximum 5MB</p>
                        <Uploader onChange = {this.handleCasatorie} accept = ".pdf" autoUpload = {false} dragable>
                            <div style={styles}>Apasa sau Adauga fisierele aici pentru upload </div>
                        </Uploader>
                    </div>:null}

                    {/* {this.state.firstLoad === true || this.state.rejectedDocs.includes("cerere_inscriere.pdf") === true?<div className = "Upload-zone">
                        <h3> Cerere de Inscriere ( <a href = "https://unefsb.ro/wp-content/uploads/2020/07/Fisa-Inscriere-Licenta.pdf" target = "_blank">descarca aici</a>)</h3>
                        <p> Fisiere acceptate: .pdf, maximum 5MB</p>
                        <Uploader onChange = {this.handleInscriere} accept = ".pdf" autoUpload = {false} dragable>
                            <div style={styles}>Apasa sau Adauga fisierele aici pentru upload </div>
                        </Uploader>
                    </div>:null}

                    {this.state.rejectedDocs.includes("cerere_inscriere2.pdf") || (this.state.firstLoad === true && this.state.subInfo.ambeleFac === true) ? 
                        <div className = "Upload-zone">
                            <h3>  Cerere de inscriere pentru a doua facultate (<a href = "https://unefsb.ro/wp-content/uploads/2020/07/Fisa-Inscriere-Licenta.pdf" target = "_blank">descarca aici</a>)</h3>
                            <p> Fisiere acceptate: .pdf, maximum 5MB</p>
                            <Uploader onChange = {this.handleInscriere2} accept = ".pdf" autoUpload = {false} dragable>
                                <div style={styles}>Apasa sau Adauga fisierele aici pentru upload </div>
                            </Uploader>
                        </div>
                    : null} */}
{/* 
                    {this.state.firstLoad === true || this.state.rejectedDocs.includes("document_gdpr.pdf") === true?<div className = "Upload-zone">
                        <h3> Declaratie privind acordarea consimtamantului in vederea prelucrarii datelor cu caracter personal (descarca <a href = "http://admitere.unefs.ro/wp-content/uploads/2022/03/FEFS-ANEXA-2a-GDPR.pdf" target = "_blank">aici</a>) </h3>
                        <p> Fisiere acceptate: .pdf, maximum 5MB</p>
                        <Uploader onChange = {this.handleGDPR} accept = ".pdf" autoUpload = {false} dragable>
                            <div style={styles}>Apasa sau Adauga fisierele aici pentru upload </div>
                        </Uploader>
                    </div>:null} */}

                    {this.state.firstLoad === true || this.state.rejectedDocs.includes("fisa_consultatii.pdf") === true?<div className = "Upload-zone">
                        <h3> Fisa de consultatii medicale </h3>
                        {/*( <a target = "_blank" href="http://admitere.unefs.ro/wp-content/uploads/2022/03/FEFS-ANEXA-1-FISA-MEDICALA.pdf">descarca aici</a>) */}
                        <p> Fisiere acceptate: .pdf, maximum 5MB</p>
                        <Uploader onChange = {this.handleFisaMedicala} accept = ".pdf" autoUpload = {false} dragable>
                            <div style={styles}>Apasa sau Adauga fisierele aici pentru upload </div>
                        </Uploader>
                    </div>:null}

                   
{/* 
                    <div className = "Upload-zone">
                        <h3> Adeverinta eliberata de către institutia de invatamant din care sa reiasa nota obtinuta la examenul de bacalaureat, doar pentru absolventii din promotia 2020 </h3>
                        <p> Fisiere acceptate: .pdf, maximum 5MB</p>
                        <Uploader onChange = {this.handleAdeverintaBac} accept = ".pdf" autoUpload = {false} dragable>
                            <div style={styles}>Apasa sau Adauga fisierele aici pentru upload </div>
                        </Uploader>
                    </div> */}
                    
                    {/* <div className = "Upload-zone">
                        <h3> Acord privind posibilitatea verificarii în Sistemul informatic integrat al invatamantului din Romania (SIIIR) a notei obtinute la examenul de bacalaureat, doar pentru promotia 2020 </h3>
                        <p> Fisiere acceptate: .pdf, maximum 5MB</p>
                        <Uploader onChange = {this.handleAcordSIR} accept = ".pdf" autoUpload = {false} dragable>
                            <div style={styles}>Apasa sau Adauga fisierele aici pentru upload </div>
                        </Uploader>
                    </div> */}
                               
                    {/* Adeverinta ambele facultati */}
                    {/* {this.state.rejectedDocs.includes("adeverinta_doua_facultati.pdf") || (this.state.firstLoad === true && this.state.subInfo.studii_univ !== "Nu am mai fost inscris la alta facultate")? 
                        <div className = "Upload-zone">
                            <h3> Adeverinta eliberata de facultate prin care iese regimul de finantare  </h3>
                            <p> Fisiere acceptate: .pdf, maximum 5MB</p>
                            <Uploader onChange = {this.handleFacultate} accept = ".pdf" autoUpload = {false} dragable>
                                <div style={styles}>Apasa sau Adauga fisierele aici pentru upload </div>
                            </Uploader>
                        </div>
                    : null}

                    {this.state.subInfo.studii_univ === "Am absolvit alta facultate"? 
                        <div>
                            {this.state.firstLoad === true || this.state.rejectedDocs.includes("diploma_licenta.pdf") === true?<div className = "Upload-zone">
                                <h3> Diploma de licenta </h3>
                                <p> Fisiere acceptate: .pdf, maximum 5MB</p>
                                <Uploader onChange = {this.handleFacultate2} accept = ".pdf" autoUpload = {false} dragable>
                                    <div style={styles}>Apasa sau Adauga fisierele aici pentru upload </div>
                                </Uploader>
                            </div>:null}
                            {this.state.firstLoad === true || this.state.rejectedDocs.includes("supliment_diploma_licenta.pdf") === true?<div className = "Upload-zone">
                                <h3> Suplimentul diplomei de licenta </h3>
                                <p> Fisiere acceptate: .pdf, maximum 5MB</p>
                                <Uploader onChange = {this.handleFacultate3} accept = ".pdf" autoUpload = {false} dragable>
                                    <div style={styles}>Apasa sau Adauga fisierele aici pentru upload </div>
                                </Uploader>
                            </div>:null}
                        </div>
                            
                    : null} */}


                    {this.state.subInfo.rrom === true? 
                        <div>
                            {this.state.firstLoad === true || this.state.rejectedDocs.includes("recomandare_scrisa_r1.pdf") === true?<div className = "Upload-zone">
                                <h3> Recomandarea scrisa din partea preşedintelui unei organizaţii legal constituite a rromilor din care să rezulte că respectivul candidat face parte din această etnie </h3>
                                <p> Fisiere acceptate: .pdf, maximum 5MB</p>
                                <Uploader onChange = {this.handleRrom1} accept = ".pdf" autoUpload = {false} dragable>
                                    <div style={styles}>Apasa sau Adauga fisierele aici pentru upload </div>
                                </Uploader>
                            </div>:null}
                            {this.state.firstLoad === true || this.state.rejectedDocs.includes("copie_legalizata_r2.pdf") === true?<div className = "Upload-zone">
                                <h3> Copie legalizată (sau conform cu originalul) a hotărârii judecătoreşti care atestă constituirea legală a organizaţiei respective </h3>
                                <p> Fisiere acceptate: .pdf, maximum 5MB</p>
                                <Uploader onChange = {this.handleRrom2} accept = ".pdf" autoUpload = {false} dragable>
                                    <div style={styles}>Apasa sau Adauga fisierele aici pentru upload </div>
                                </Uploader>
                            </div>:null}
                            {this.state.firstLoad === true || this.state.rejectedDocs.includes("declaratie_raspundere_r3.pdf") === true?<div className = "Upload-zone">
                                <h3> Declaratie pe proprie raspundere ca respectivul candidat face parte din etnia rroma </h3>
                                <p> Fisiere acceptate: .pdf, maximum 5MB</p>
                                <Uploader onChange = {this.handleRrom3} accept = ".pdf" autoUpload = {false} dragable>
                                    <div style={styles}>Apasa sau Adauga fisierele aici pentru upload </div>
                                </Uploader>
                            </div>:null}
                        </div>

                    : null}

                    {this.state.subInfo.dizabilitati === true? 
                        <div>
                            {this.state.firstLoad === true || this.state.rejectedDocs.includes("adeverinta_dizabilitati.pdf") === true?<div className = "Upload-zone">
                                <h3> Adeverinta persoane cu dizabilitati </h3>
                                <p> Fisiere acceptate: .pdf, maximum 5MB</p>
                                <Uploader onChange = {this.handleDizabilitati} accept = ".pdf" autoUpload = {false} dragable>
                                    <div style={styles}>Apasa sau Adauga fisierele aici pentru upload </div>
                                </Uploader>
                            </div>:null}
                        </div>

                    : null}

                {this.state.subInfo.optionList.includes("Sport si Performanta Motrica - Sportivi de performanta - fara examen") === true ||
                this.state.subInfo.optionList.includes("Sport si Performanta Motrica - IFR") === true || this.state.subInfo.optionList.includes("Educatie Fizica si Sportiva - IFR") === true
                ? 
                        <div>
                            <div className = "Upload-zone">
                                <h3> Anexa 3 Adeverinta eliberata de federatia de specialitate 
                                    {/* <br/> Canditatii care au obtinut locurile I – III si mentiune la Olimpiada Nationala de Pregatire Teoretica Sportiva vor prezenta în copie legalizata sau conform cu originalul după diploma obtinuta. */}
                                </h3>
                                <p> Fisiere acceptate: .pdf, maximum 5MB</p>
                                <Uploader onChange = {this.handleSport1} accept = ".pdf" autoUpload = {false} dragable>
                                    <div style={styles}>Apasa sau Adauga fisierele aici pentru upload </div>
                                </Uploader>
                            </div>
                            {/* {this.state.firstLoad === true || this.state.rejectedDocs.includes("adeverinta_federatie_s1.pdf") === true?<div className = "Upload-zone">
                                <h3> Anexa 3 Adeverinta eliberata de federatia de specialitate 
                                    <br/> Canditatii care au obtinut locurile I – III si mentiune la Olimpiada Nationala de Pregatire Teoretica Sportiva vor prezenta în copie legalizata sau conform cu originalul după diploma obtinuta.
                                </h3>
                                <p> Fisiere acceptate: .pdf, maximum 5MB</p>
                                <Uploader onChange = {this.handleSport1} accept = ".pdf" autoUpload = {false} dragable>
                                    <div style={styles}>Apasa sau Adauga fisierele aici pentru upload </div>
                                </Uploader>
                            </div>:null} */}
                            {/* <div className = "Upload-zone">
                                <h3> Canditatii care au obtinut locurile I – III si mentiune la Olimpiada Nationala de Pregatire Teoretica Sportiva vor prezenta în copie legalizata sau conform cu originalul după diploma obtinuta. </h3>
                                <p> Fisiere acceptate: .pdf, maximum 5MB</p>
                                <Uploader onChange = {this.handleSport2} accept = ".pdf" autoUpload = {false} dragable>
                                    <div style={styles}>Apasa sau Adauga fisierele aici pentru upload </div>
                                </Uploader>
                            </div> */}
                          
                        </div>

                    : null}

                    {this.state.subInfo.optionList.includes("Sport si Performanta Motrica - Zi - Engleza") === true? 
                        <div>
                            <div className = "Upload-zone">
                                <h3> Atestat Cambridge/TOEFL </h3>
                                <p> Fisiere acceptate: .pdf, maximum 5MB</p>
                                <Uploader onChange = {this.handleLimba} accept = ".pdf" autoUpload = {false} dragable>
                                    <div style={styles}>Apasa sau Adauga fisierele aici pentru upload </div>
                                </Uploader>
                            </div>
    
                          
                        </div>

                    : null}

                    {/*  daca e rrom 1) Recomandarea scrisă din partea preşedintelui unei organizaţii legal constituite a rromilor din care să rezulte că respectivul candidat face parte din această etnie
                                    2) Copie legalizată (sau conform cu originalul) a hotărârii judecătoreşti care atestă constituirea legală a organizaţiei respective
                                    3) Declaraţie pe proprie răspundere că respectivul candidat face parte din etnia rromă;
                    
                    */}

<p> In caz de probleme tehnice, va rugam sa ne contactacti la admitere_fefs@unefs.ro/admitere_fkt@unefs.ro </p>

                    { this.state.rejectedDocs.length > 0? <Button onClick = {this.sendData} appearance = "primary" > Adauga documentele </Button>:null}

                    {/* {
                        this.state.subInfo.optionList.includes("Sport si Performanta Motrica - IFR") === true || this.state.subInfo.optionList.includes("Educatie Fizica si Sportiva - IFR") === true ?
                    <Button onClick = {this.sendData} appearance = "primary" > Adauga documentele </Button>:null }

                    <Button onClick = {() => window.location.reload()}appearance = "ghost" > Inapoi</Button> */}

                </div>
            </div>

        )
    }
}

export default Documents;