import React from 'react'
import './stylesheets/ConfirmEmail.scss'
import {Button, Input} from 'rsuite'
import logo from '../assets/logo.png'
import {toast} from 'react-toastify'
import axios from 'axios'
class ConfirmEmail extends React.Component{

    state = {
        parola : "",
        parola2: ""
    }
    backToLogin = () => {

        this.props.history.push('/login')
    }
    

    handleChange = (value, event) => {
        this.setState({
            [event.target.name] : value
        })
    }
    
    resetPassword = () => {
        if ( this.state.parola === "" || this.state.parola2 === "" )
        {
            toast.error("Nu ati completat campurile obligatorii")
            return;
        }
        if ( this.state.parola !== this.state.parola2)
        {
            toast.error("Cele doua parole nu sunt identice")
            return;
        }

        axios.post('/api/user/resetPassword', {password : this.state.parola, id : this.props.match.params.id})
        .then (response => {
            toast.success("Parola resetata cu success!")
            this.props.history.push('/login')
        })
        .catch( e=> {
            toast.error("S-a produs o eroare!")

        })
    }
    render(){
        return(
            <div className = "ConfirmEmail-container">
                <div className = "ConfirmEmail-content">
                    <div className = "ConfirmEmail-top">
                        <img id = "logo" src = {logo} />
                        <h2> Modul Inscrieri UNEFS </h2>
                        <div className = "element">
                            <p> Parola Noua </p>
                            <Input onChange = {this.handleChange} name = "parola" className = "input" type="password" placeholder = "Parola"/>
                        </div>
                        <div className = "element">
                            <p> Repeta Parola Noua </p>
                            <Input onChange = {this.handleChange} name = "parola2" className = "input" type="password" placeholder = "Repeta Parola"/>
                        </div>
                        <Button onClick = {this.resetPassword} appearance = "primary" > Reseteaza Parola </Button>

                    </div>
                </div>
            </div>

        )
    }

}

export default ConfirmEmail;