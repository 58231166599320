import React from 'react'
import './stylesheets/ParticipantInfo.scss'
import {Button, Divider} from 'rsuite'
import axios from 'axios'
import download from 'downloadjs'

class ParticipantInfo extends React.Component{

    state = {
        content : "",
        observatii: ""

    }

    downloadFisa = async () =>{

        // const res = await axios.get(')
        const res = await fetch("https://unefsb.ro/wp-content/uploads/2020/07/Fisa-Inscriere-Licenta.pdf");
        const blob = await res.blob();
        download(blob, 'FisaInscriere2022.pdf');
    }

    downloadLegitimatie = async () =>{

        // const res = await axios.get(')
        const res = await fetch("/api/submission/downloadFile/" + localStorage.getItem('id') + "/" + "legitimatieUNEFS.pdf");
        const blob = await res.blob();
        download(blob, 'legitimatieUNEFS.pdf');
    }
    componentDidMount() {

        axios.get('/api/user/getUserInfo/' + localStorage.getItem('id'))
        .then( response => {

            console.log(response.data)
            this.setState({
                content : response.data
            })
        })

        
        axios.get('/api/user/getSub/' + localStorage.getItem('id'))
        .then( response => {

            console.log(response.data)
            this.setState({
                observatii : response.data.observatii
            })
        })
    }

    render(){

        return(
            <div className = "ParticipantInfo-container">
                <div className = "ParticipantInfo-content">
                    <h3 style = {{marginBottom: "20px"}}> Informatii Generale </h3>
                    <p> Nume Participant : <strong>{this.state.content.nume} {this.state.content.prenume}</strong> </p>
                    <p> Numar Legitimatie: <strong>{(this.state.content.legitimatie === 'Not assigned')?"-":this.state.content.legitimatie}</strong></p>
                    <p> Sala Repartizare: - </p>
                    <p> Observatii Comisie: {this.state.observatii} </p>

                    <Button onClick = {this.downloadLegitimatie} disabled = {(this.state.content.legitimatie === 'Not assigned')?true:true} appearance = "primary"> Download Legitimatie </Button>
                    {/* <a href="http://rest-unefs.syntract.ro/wp-content/uploads/2021/07/fisa-de-inscriere-anuala.pdf" target ="_blank"> <Button appearance = "primary">Download Fisa Inscriere </Button> </a> */}

                    <Button disabled = {this.state.content.can_edit === true?false: true} onClick = {this.props.moveToDetalii}  appearance = "primary"> Editeaza informatii generale </Button>
                    {/* <Button onClick = {this.props.moveToDetalii}  appearance = "primary"> Editeaza informatii generale </Button> */}

                </div>
            </div>
        )
    }
}

export default ParticipantInfo;