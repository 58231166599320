import React from 'react'
import {Button, Nav, Icon} from 'rsuite'
import './stylesheets/Dashboard.scss'

//asets
import logo from '../../assets/logo.png'

//components
import MainAdmin from './Main'
import {toast} from 'react-toastify'


class DashboardAdmin extends React.Component{

    state = {
        activeTab : "home",
        initial : true,
        status : "studenti",
        userInfo : {},
        loading : true
    }

    handleNavChange = (value) => {

        this.setState({
            activeTab : value
        })
    }

    handleClick = () => {

        let current = this.state.signup_active;

        this.setState({
            signup_active : !current
        })
    }
    logout = () => {
        toast.success("Delogat!")
        localStorage.clear('id') 
        localStorage.clear('jwt') 
        this.props.history.push('/login')
    }
   

    render() {

        

        let renderElement = <p> Test </p>;
        // {(this.state.signup_active === false)? <Initial_Context handleClick = {this.handleClick} />: <Signup /> }
        if ( this.state.status === 'studenti')
            renderElement = <MainAdmin />

        return(
            <div className = "DashboardAdmin-container">
                <div className = "DashboardAdmin-content">
                    <div className = "Dashboard-navbar">
                        <img id = "logo" src = {logo} />
                        <Nav reversed appearance="subtle" vertical activeKey={this.state.activeTab} onSelect={this.handleNavChange} >
                            <Nav.Item eventKey="home" icon={<Icon icon="home" />}>
                            Home
                            </Nav.Item>
                            <Nav.Item eventKey="about">Stiri</Nav.Item>
                            <Nav.Item onClick = {this.logout} eventKey="about">Log Out</Nav.Item>
                        </Nav>
                    </div>
                    <div className = "Dashboard-container">
                        {renderElement}
                    </div>
                </div>
            </div>

        )
    }

}


export default DashboardAdmin;