import React from 'react'
import './NewsElement.scss'

class NewsElement extends React.Component{


    render(){

        return(
            <div className = "NewsElement-container">
                <div className = "NewsElement-content">
                    <p id = "title"> {this.props.title} </p>
                    <p id = "date"> {this.props.date} </p>
                    <p id = "content"> {this.props.content} </p>
                </div>
            </div>
        )
    }
}

export default NewsElement;