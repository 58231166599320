import React from 'react'
import './stylesheets/Main.scss'

//components
import StudentList from './components/StudentList'
import Summary from './components/Summary'


class MainAdmin extends React.Component{


    render(){

        return(
            <div className = "MainAdmin-container">
                <div className = "MainAdmin-content">
                    <div className = "Block-content">
                        <StudentList />
                    </div>
                    <Summary />
                </div>
            </div>

        )
    }
}


export default MainAdmin;