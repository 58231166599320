import React from 'react'
import './OneStudent.scss'
import {Button, Icon} from 'rsuite'
import axios from 'axios'

class OneStudent extends React.Component{

    state = {
        details : []
    }

    componentDidMount() {

        // console.log(this.props.id)
        axios.get('/api/admin/getSub/' + this.props.id)
        .then (
            response => {
                this.setState({
                    details : response.data
                })
                // console.log(response);
            }
        )

    }
    render(){

        let emailString = "mailto:" + this.props.email;
        let data_nasterii = this.props.data_nasterii.split(" ");
        let color = "black";
        let data = this.props.submission[0].date.split("T");
        let data2 = data[0].split("-");
    
        let data3 = data[1].split(".")[0];
        if ( this.props.submission.length > 0 && typeof this.props.submission[0].status !== 'undefined')
        {
            if ( this.props.submission[0].status === 1)
                color = "red"
            else
            if ( this.props.can_edit === false)
                color = "orange"
            
            if ( this.props.submission[0].status === 2 )
                color = "green"

            if ( this.props.submission[0].status === 3 )
                color = "yellow"

            
        }
        console.log(this.props.ambeleFac)
        return(

            <tr className = "OneStudent-container">
                <td>{this.props.submission.length > 0?this.props.submission[0].comisie:null}</td>
                <td> <Icon onClick = {() => this.props.toggleModalEdit(this.props.id, this.props.approved)} icon = 'edit' className = "document-button"> </Icon></td>
                <td style ={{fontSize:"15px"}}> {data2[2]} Iulie  </td>
                <td>{(this.props.legitimatie === 'Not assigned')?"-":this.props.legitimatie}</td>
                <td>{(this.props.has_submited_documents === true)? "Incarcate"  : ""}</td>
                <td> <Icon onClick = {() => this.props.toggleModal(this.props.id, this.props.approved)} style = {{color : color}} icon = 'info' className = "document-button"> </Icon></td>

                <td>{this.props.nume} {this.props.submission.length > 0 && typeof this.props.submission[0].casatorie !== "undefined"?"(" + this.props.submission[0].casatorie +")":null}</td>
                <td>{this.state.details.initiala}</td>
                <td>{this.props.prenume}</td>
                <td> {this.props.submission.length > 0?this.props.submission[0].medieBAC:null} </td>
                <td> {this.props.submission.length > 0?this.props.submission[0].medieEdFizica:null} </td>
                <td> {this.props.sex} </td>
                <td> <a href={emailString}> {this.props.email} </a> </td>
                <td> {this.props.submission.length > 0?this.props.submission[0].telefonMobil:null}</td>
                <td> {data_nasterii[2] + " " + data_nasterii[1] + " " + data_nasterii[3]} </td>
                <td> {this.props.submission.length > 0?this.props.submission[0].facultatea:null} </td>
                <td> {this.props.submission[0].optionList.join(", ")}</td>

                <td> {this.props.submission[0].proba} </td>
            </tr>


        )
    }
}

export default OneStudent;